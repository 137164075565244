import { List, Spin } from 'antd'
import Card from 'components/Card'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useTranslation } from 'react-i18next'

import './logbookRemarks.less'
import MoreInfoContent from './MoreInfoContent'

const LogbookRemarks = ({ isTimestampUTC, isLoading, remarks }) => {
  const { t } = useTranslation()

  const { Item } = List

  const renderListItem = item => (
    <Item className="logbook-remarks__list-item">
      <div className="logbook-remarks__list-item-container">
        <Text>{item.remark}</Text>
        <Timestamp
          date={item.loggedAt}
          dateFormat={timeDateAndYear}
          isLabel
          isUTC={isTimestampUTC}
        />
      </div>
    </Item>
  )

  return (
    <Card
      className="logbook-remarks__container"
      moreInfoContent={<MoreInfoContent />}
      moreInfoTitle={t('portCall.logbook.logbook')}
      title={t('portCall.logbook.logbook')}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <List
          className="logbook-remarks__list"
          dataSource={remarks}
          renderItem={renderListItem}
        />
      )}
    </Card>
  )
}

LogbookRemarks.defaultProps = {
  isTimestampUTC: false,
  remarks: [],
}

LogbookRemarks.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isTimestampUTC: PropTypes.bool,
  remarks: propTypes.logbookRemarks,
}

export default LogbookRemarks
