import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './settings.less'
import SettingsCard from './components/SettingsCard'

const Settings = ({
  emailNotificationsPreferences,
  onSaveToggle,
  isRequestingEmailNotificationsPreferences,
}) => {
  const { t } = useTranslation()
  const settingGroups = [
    {
      items: [{ id: 'gangwayRigged' }, { id: 'vesselReadyForOps' }],
      title: 'vesselArrival',
    },
    {
      items: [
        { id: 'cargoOpsCommence' },
        { id: 'forecast' },
        { id: 'cargoOpsComplete' },
        { id: 'tempCargoStop' },
        { id: 'resumeCargo' },
      ],
      title: 'cargoOperations',
    },
    {
      items: [{ id: 'dischargeComplete' }, { id: 'dischargeStarted' }],
      title: 'discharge',
    },
    {
      items: [{ id: 'loadingComplete' }, { id: 'loadingStarted' }],
      title: 'loading',
    },
    {
      items: [{ id: 'logbookRemark' }],
      title: 'logbook',
    },
  ]

  return (
    <div className="settings__container">
      <Heading level={1}>{t('settings.settings')}</Heading>
      <SettingsCard
        groups={settingGroups}
        isLoading={isRequestingEmailNotificationsPreferences}
        onSaveToggle={onSaveToggle}
        title={t('settings.emailNotifications')}
        toggleStates={emailNotificationsPreferences}
      />
    </div>
  )
}

Settings.propTypes = {
  emailNotificationsPreferences: PropTypes.object.isRequired,
  isRequestingEmailNotificationsPreferences: PropTypes.bool.isRequired,
  onSaveToggle: PropTypes.func.isRequired,
}

export default Settings
