import notificationTypes from 'constants/notificationTypes'
import PropTypes from 'prop-types'

import scheduleStatus from './scheduleStatus'

export default PropTypes.shape({
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  schedule: PropTypes.shape({
    message: PropTypes.string.isRequired,
    status: scheduleStatus.isRequired,
  }),
  timestamp: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    notificationTypes.COMPLETION,
    notificationTypes.FORECAST,
    notificationTypes.LOGBOOK,
    notificationTypes.REMARK,
  ]).isRequired,
})
