// Request General

const REQUEST_PORT_CALL_GENERAL = 'REQUEST_PORT_CALL_GENERAL'
const SET_REQUESTING_PORT_CALL_GENERAL = 'SET_REQUESTING_PORT_CALL_GENERAL'
const PORT_CALL_GENERAL_REQUEST_SUCCESSFUL =
  'PORT_CALL_GENERAL_REQUEST_SUCCESSFUL'

// Departure estimation

const REQUEST_PORT_CALL_DEPARTURE_ESTIMATION =
  'REQUEST_PORT_CALL_DEPARTURE_ESTIMATION'
const SET_REQUESTING_PORT_CALL_DEPARTURE_ESTIMATION =
  'SET_REQUESTING_PORT_CALL_DEPARTURE_ESTIMATION'
const PORT_CALL_DEPARTURE_ESTIMATION_REQUEST_SUCCESSFUL =
  'PORT_CALL_DEPARTURE_ESTIMATION_REQUEST_SUCCESSFUL'

// Summary tab requests

const REQUEST_PORT_CALL_SUMMARY_TAB = 'REQUEST_PORT_CALL_SUMMARY_TAB'

const SET_REQUESTING_PORT_CALL_LOGBOOK_REMARKS =
  'SET_REQUESTING_PORT_CALL_LOGBOOK_REMARKS'
const PORT_CALL_LOGBOOK_REMARKS_REQUEST_SUCCESSFUL =
  'PORT_CALL_LOGBOOK_REMARKS_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_MILESTONES =
  'SET_REQUESTING_PORT_CALL_MILESTONES'
const PORT_CALL_MILESTONES_REQUEST_SUCCESSFUL =
  'PORT_CALL_MILESTONES_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_REPORTS_SUMMARY =
  'SET_REQUESTING_PORT_CALL_REPORTS_SUMMARY'
const PORT_CALL_REPORTS_SUMMARY_REQUEST_SUCCESSFUL =
  'PORT_CALL_REPORTS_SUMMARY_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_CONTAINER_MOVES_PROGRESS =
  'SET_REQUESTING_PORT_CALL_CONTAINER_MOVES_PROGRESS'
const PORT_CALL_CONTAINER_MOVES_PROGRESS_REQUEST_SUCCESSFUL =
  'PORT_CALL_CONTAINER_MOVES_PROGRESS_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_GANTRY_MOVES_SUMMARY =
  'SET_REQUESTING_PORT_CALL_GANTRY_MOVES_SUMMARY'
const PORT_CALL_GANTRY_MOVES_SUMMARY_REQUEST_SUCCESSFUL =
  'PORT_CALL_GANTRY_MOVES_SUMMARY_REQUEST_SUCCESSFUL'

// Ship overview tab requests

const REQUEST_PORT_CALL_SHIP_OVERVIEW_TAB =
  'REQUEST_PORT_CALL_SHIP_OVERVIEW_TAB'

const SET_REQUESTING_PORT_CALL_SHIP_OVERVIEW =
  'SET_REQUESTING_PORT_CALL_SHIP_OVERVIEW'
const PORT_CALL_SHIP_OVERVIEW_REQUEST_SUCCESSFUL =
  'PORT_CALL_SHIP_OVERVIEW_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_GEARBOX_LOCATIONS =
  'SET_REQUESTING_PORT_CALL_GEARBOX_LOCATIONS'
const PORT_CALL_GEARBOX_LOCATIONS_REQUEST_SUCCESSFUL =
  'PORT_CALL_GEARBOX_LOCATIONS_REQUEST_SUCCESSFUL'

const SET_REQUESTING_PORT_CALL_DRAFT_SURVEY =
  'SET_REQUESTING_PORT_CALL_DRAFT_SURVEY'
const PORT_CALL_DRAFT_SURVEY_REQUEST_SUCCESSFUL =
  'PORT_CALL_DRAFT_SURVEY_REQUEST_SUCCESSFUL'

// Productivity tab requests

const REQUEST_PORT_CALL_PRODUCTIVITY = 'REQUEST_PORT_CALL_PRODUCTIVITY'
const SET_REQUESTING_PORT_CALL_PRODUCTIVITY =
  'SET_REQUESTING_PORT_CALL_PRODUCTIVITY'
const PORT_CALL_PRODUCTIVITY_REQUEST_SUCCESSFUL =
  'PORT_CALL_PRODUCTIVITY_REQUEST_SUCCESSFUL'

// Comments

const REQUEST_PORT_CALL_COMMENTS_TAB = 'REQUEST_PORT_CALL_COMMENTS_TAB'
const SET_REQUESTING_PORT_CALL_COMMENTS = 'SET_REQUESTING_PORT_CALL_COMMENTS'
const PORT_CALL_COMMENTS_REQUEST_SUCCESSFUL =
  'PORT_CALL_COMMENTS_REQUEST_SUCCESSFUL'

const POST_PORT_CALL_COMMENT = 'POST_PORT_CALL_COMMENT'
const SET_IS_POSTING_COMMENT = 'SET_IS_POSTING_COMMENT'

const ADD_COMMENT_DRAFT = 'ADD_COMMENT_DRAFT'
const CLEAR_COMMENT_DRAFT = 'CLEAR_COMMENT_DRAFT'

// Reports

const DOWNLOAD_PORT_CALL_REPORT = 'DOWNLOAD_PORT_CALL_REPORT'
const DOWNLOAD_TERMINAL_PERFORMANCE_REPORT =
  'DOWNLOAD_TERMINAL_PERFORMANCE_REPORT'

export default {
  ADD_COMMENT_DRAFT,
  CLEAR_COMMENT_DRAFT,
  DOWNLOAD_PORT_CALL_REPORT,
  DOWNLOAD_TERMINAL_PERFORMANCE_REPORT,
  PORT_CALL_COMMENTS_REQUEST_SUCCESSFUL,
  PORT_CALL_CONTAINER_MOVES_PROGRESS_REQUEST_SUCCESSFUL,
  PORT_CALL_DEPARTURE_ESTIMATION_REQUEST_SUCCESSFUL,
  PORT_CALL_DRAFT_SURVEY_REQUEST_SUCCESSFUL,
  PORT_CALL_GANTRY_MOVES_SUMMARY_REQUEST_SUCCESSFUL,
  PORT_CALL_GEARBOX_LOCATIONS_REQUEST_SUCCESSFUL,
  PORT_CALL_GENERAL_REQUEST_SUCCESSFUL,
  PORT_CALL_LOGBOOK_REMARKS_REQUEST_SUCCESSFUL,
  PORT_CALL_MILESTONES_REQUEST_SUCCESSFUL,
  PORT_CALL_PRODUCTIVITY_REQUEST_SUCCESSFUL,
  PORT_CALL_REPORTS_SUMMARY_REQUEST_SUCCESSFUL,
  PORT_CALL_SHIP_OVERVIEW_REQUEST_SUCCESSFUL,
  POST_PORT_CALL_COMMENT,
  REQUEST_PORT_CALL_COMMENTS_TAB,
  REQUEST_PORT_CALL_DEPARTURE_ESTIMATION,
  REQUEST_PORT_CALL_GENERAL,
  REQUEST_PORT_CALL_PRODUCTIVITY,
  REQUEST_PORT_CALL_SHIP_OVERVIEW_TAB,
  REQUEST_PORT_CALL_SUMMARY_TAB,
  SET_IS_POSTING_COMMENT,
  SET_REQUESTING_PORT_CALL_COMMENTS,
  SET_REQUESTING_PORT_CALL_CONTAINER_MOVES_PROGRESS,
  SET_REQUESTING_PORT_CALL_DEPARTURE_ESTIMATION,
  SET_REQUESTING_PORT_CALL_DRAFT_SURVEY,
  SET_REQUESTING_PORT_CALL_GANTRY_MOVES_SUMMARY,
  SET_REQUESTING_PORT_CALL_GEARBOX_LOCATIONS,
  SET_REQUESTING_PORT_CALL_GENERAL,
  SET_REQUESTING_PORT_CALL_LOGBOOK_REMARKS,
  SET_REQUESTING_PORT_CALL_MILESTONES,
  SET_REQUESTING_PORT_CALL_PRODUCTIVITY,
  SET_REQUESTING_PORT_CALL_REPORTS_SUMMARY,
  SET_REQUESTING_PORT_CALL_SHIP_OVERVIEW,
}
