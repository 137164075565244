import Heading from 'components/Heading'
import React from 'react'

import atBayGantry from './Images/at-bay-gantry.png'
import lashingComplete from './Images/lashing-complete.png'

const LogLashingAndTwistlocks = () => (
  <div>
    <Heading level={2}>Logging lashing and twistlock checks</Heading>

    <p>
      Once container moves have been carried out on bays, an option to mark
      lashing and twistlocks as checked for each bay can be selected in the bay
      context menu.
    </p>
    <img src={atBayGantry} />

    <p>
      When checks have been carried out, press the required button to mark the
      check as complete.
    </p>
    <p>The icon will be marked with a tick.</p>

    <img src={lashingComplete} />
    <p>
      Checks can also be undone in case of error. Press the required button
      again to undo a check.
    </p>
  </div>
)

export default LogLashingAndTwistlocks
