import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import './footerLink.less'

const FooterLink = ({ iconComponent, path, title, isActive, url }) => {
  const navigate = useNavigate()

  const handleNavigateToPage = () => navigate(path)

  const containerStyle = getClassNames('footer-link__container', {
    'footer-link__active': isActive,
  })

  const renderPath = () => (
    <div className={containerStyle} onClick={handleNavigateToPage}>
      {iconComponent}
      <p>{title}</p>
    </div>
  )

  const renderUrl = () => (
    <div className="footer-link__container">
      {iconComponent}
      <a href={url}>{title}</a>
    </div>
  )

  return path ? renderPath() : url ? renderUrl() : null
}

FooterLink.defaultProps = {
  isActive: false,
  path: null,
  url: null,
}

FooterLink.propTypes = {
  iconComponent: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default FooterLink
