import Heading from 'components/Heading'
import React from 'react'

import portCallReport from './Images/port-call-report.png'
import terminalPerformanceHover from './Images/terminal-performance-hover.png'
import terminalPerformanceReport from './Images/terminal-performance-report.png'

const HistoricPortCalls = () => (
  <div>
    <Heading level={2}>Historic Port Calls</Heading>

    <p>
      Accessible from the Recently Completed section on the Dashboard Overview
      page, or by searching for a vessel or port, historic port calls show how
      previous port calls have been conducted.
    </p>
    <p>
      While the Productivity section for a historic port call is the same as for
      an Active Port Call, the Cargo Operations page is changed to show the port
      call data as a review.
    </p>

    <Heading isDark level={3}>
      Port call report
    </Heading>
    <img src={portCallReport} />
    <p>
      The Port call report shows an overview of the containers discharged,
      loaded and cut (cancelled), along with the duration of cargo operations
      and any shift changes or operation breaks.
    </p>
    <p>
      A PDF is available to download with further information on the principle
      milestone times, gearbox locations on arrival and departure and a
      chronological record of gantry actions and movements. Arrival and
      departure drafts, gearbox positions and statistics on number of moves and
      special cargo are also included within the PDF.
    </p>
    <p>
      The Port Call Report is designed as an eventual replacement of the paper
      logbook. Until this is implemented, it still serves as a chronological
      searchable record of port operations for a vessel.
    </p>

    <Heading isDark level={3}>
      Terminal performance report
    </Heading>
    <img src={terminalPerformanceReport} />
    <p>
      The Terminal performance report goes into greater detail on both the
      timing and number of moves carried out during the port call.
    </p>
    <p>
      As the definitions may have differences to those provided by the container
      terminal, CargoMate users can hover over the topic to get more information
      on how the statistics are calculated.
    </p>
    <img src={terminalPerformanceHover} />
    <p>
      The Terminal performance report is available to download as a CSV file for
      compatibility with spreadsheet and database applications.
    </p>
  </div>
)

export default HistoricPortCalls
