import Heading from 'components/Heading'
import React from 'react'

import feedback from './Images/feedback.png'

const Feedback = () => (
  <div>
    <Heading level={2}>Feedback</Heading>

    <img src={feedback} />
    <p>
      Feedback is most important for improving CargoMate. All features currently
      available in CargoMate, such as viewing IMDG placards and long-crane
      calculations, have been directly suggested by our users.
    </p>
    <p>To rate your experience with CargoMate, please select a face rating.</p>
    <p>The text box allows you to enter any feedback you like. This can be:</p>
    <ul>
      <li>Feedback</li>
      <li>Improvements</li>
      <li>Bugs or problems</li>
    </ul>
    <p>
      Feedback is anonymous, so please don’t send any names. We know only which
      vessel and port call the feedback originates from.
    </p>
  </div>
)

export default Feedback
