import * as Sentry from '@sentry/browser'
import { getEnvironment, isDevEnvironment } from 'global/util/environment'
import { createRoot } from 'react-dom/client'
import includes from 'lodash/includes'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import React from 'react'

import './index.less'
import 'domain/i18n/i18n'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './store/configureStore'
import App from './App'

if (!isDevEnvironment()) {
  Sentry.init({
    beforeSend(event) {
      // This method makes sure the same error is only reported once to Sentry.

      const reportedErrors = JSON.parse(
        sessionStorage.getItem('reportedErrors') || '[]'
      )

      if (includes(reportedErrors, event.message)) {
        // Already reported, returning null so it won't be reported again.
        return null
      }

      sessionStorage.setItem(
        'reportedErrors',
        JSON.stringify([...reportedErrors, event.message])
      )

      // Returning the event means it'll be reported to sentry.
      return event
    },
    dsn: 'https://77d5b3fe5a41426980809991f5ee0efb@sentry.io/1884104',
    environment: getEnvironment(),
    release: `web-dashboard@${WEB_DASHBOARD_VERSION}`,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

if (container) {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
} else {
  throw new Error('No root element found.')
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
