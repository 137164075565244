import { Button, Spin, Switch, Tooltip } from 'antd'
import Card from 'components/Card'
import Heading from 'components/Heading'
import { InfoOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './settingsCard.less'

const SettingsCard = ({
  groups,
  isLoading,
  onSaveToggle,
  toggleStates,
  title,
}) => {
  const { t } = useTranslation()

  const handleToggle = itemId =>
    onSaveToggle({
      ...toggleStates,
      [itemId]: !toggleStates[itemId],
    })

  return (
    <Card className="settings__card" title={title}>
      <Text className="settings__card-subtitle" isLabel>
        {t('settings.selectUpdatesForEmailNotifications')}
      </Text>
      {isLoading ? (
        <div className="settings__card-spin">
          <Spin size="large" />
        </div>
      ) : (
        groups.map(group => (
          <div className="settings__group" key={group.title}>
            <Heading level={5}>{t(`settings.${group.title}`)}</Heading>
            {group.items.map(item => (
              <div className="settings__item" key={item.id}>
                <span>{t(`settings.items.${item.id}.label`)}</span>
                <span className="settings__item-switch-container">
                  <Switch
                    checked={toggleStates[item.id]}
                    className="settings__item-switch"
                    onChange={() => handleToggle(item.id)}
                  />
                  <Tooltip
                    placement="right"
                    title={t(`settings.items.${item.id}.tooltip`)}
                  >
                    <Button
                      className="settings__info-button"
                      icon={<InfoOutlined />}
                      shape="circle"
                      size="small"
                    />
                  </Tooltip>
                </span>
              </div>
            ))}
          </div>
        ))
      )}
    </Card>
  )
}

SettingsCard.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toggleStates: PropTypes.object.isRequired,
}

export default SettingsCard
