import Heading from 'components/Heading'
import React from 'react'

const CargoMateDevice = () => (
  <div>
    <Heading level={2}>CargoMate Device</Heading>

    <p>
      CargoMate is provided to the vessel ready to use on its own specific
      device.
    </p>
    <p>
      The device is an Android mobile phone that is remotely managed by
      Intelligent Cargo Systems to enable automatic software updates and remote
      maintenance and support.
    </p>
    <p>
      Pre-installed in the device is a global sim card that connects to the
      local cell network in each port, and establishes a data connection between
      the device and the CargoMate database. Wifi, Bluetooth and Near-Field
      Communication (NFC) connections are not currently supported for data
      transfer.
    </p>
    <p>
      Accessories provided with the device include an AC charger (with adaptor
      for onboard power, if applicable), a USB cable
      <strong> for charging only</strong>, and a USB stick and USB OTG
      (‘On-the-go’) adaptor. A neck strap is also permanently attached to the
      device for ease of carrying when climbing ladders.
    </p>
    <p>
      If you require additional spare parts or if your device becomes damaged or
      defective, then please contact Kirsten Beskid or CargoMate. We will
      exchange defective devices with replacements in suitable ports.
    </p>
    <p>
      CargoMate devices are rigorously tested and chosen for use onboard ship.
      The devices are rugged, waterproof and can withstand most drops. A screen
      protector is applied to prevent normal wear and tear and the screens can
      be used whilst wearing gloves.
    </p>
    <p>
      A fully charged battery on the device can usually last an entire port
      call, but do get in touch if you notice any issues with the device not
      holding a sufficient battery charge.
    </p>
  </div>
)

export default CargoMateDevice
