import * as notificationsActions from 'domain/Notifications/actions'
import * as userActions from 'domain/User/actions'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import { connect } from 'react-redux'
import every from 'lodash/every'
import find from 'lodash/find'
import first from 'lodash/first'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import news from 'global/news'
import { NOTIFICATIONS_POLLING_INTERVAL } from 'global/settings'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import userRoles from 'constants/userRoles'

import Header from './Header'

const HeaderContainer = ({
  isNotificationsBarOpen,
  isUserSSO,
  requestNotifications,
  setBrowserNotificationPermissionStatus,
  setIsNotificationsBarOpen,
  signedInAs,
  signOut,
  totalNoOfNewNotifications,
  userRole,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [hasUnseenNews, setHasUnseenNews] = useState(false)

  const [shouldShowWhatsNewNotification, setShouldShowWhatsNewNotification] =
    useState(false)

  const [
    shouldShowEnableNotificationsPrompt,
    setShouldShowEnableNotificationsPrompt,
  ] = useState(false)

  const onCancelEnableNotificationsPrompt = () =>
    setShouldShowEnableNotificationsPrompt(false)

  useEffect(() => {
    if (!signedInAs) {
      navigate(paths.login)
    }
  }, [navigate, signedInAs])

  // Handles dashboard update notifications
  useEffect(() => {
    if (!signedInAs) {
      return
    }

    if (isEqual(location.pathname, paths.whatIsNew)) {
      setHasUnseenNews(false)
    } else {
      const seenNewsIds = JSON.parse(localStorage.getItem('news-id-seen')) || []

      const newsForUser = news.filter(item => item.userRoles.includes(userRole))
      const currentNewsIds = newsForUser.map(item => item.id)

      setHasUnseenNews(!every(currentNewsIds, id => seenNewsIds.includes(id)))
      setShouldShowWhatsNewNotification(
        !find(seenNewsIds, newsId => newsId === first(currentNewsIds))
      )
    }
  }, [location, signedInAs, userRole])

  const shouldShowPortCallNotifications = userRole !== userRoles.SHIP

  // Handles requesting port call notifications
  useEffect(() => {
    if (shouldShowPortCallNotifications) {
      requestNotifications({ navigate })

      const requestInterval = setInterval(() => {
        requestNotifications({ navigate })
      }, NOTIFICATIONS_POLLING_INTERVAL)

      return () => clearInterval(requestInterval)
    }
  }, [navigate, requestNotifications, shouldShowPortCallNotifications])

  const closeWhatsNewNotification = () =>
    setShouldShowWhatsNewNotification(false)

  const toggleNotificationsBarOpen = () =>
    setIsNotificationsBarOpen(!isNotificationsBarOpen)

  // Handles checking and requesting browser notification permission
  useEffect(() => {
    const areNotificationsSupported = !!('Notification' in window)
    if (!areNotificationsSupported) {
      setBrowserNotificationPermissionStatus(
        browserNotificationPermissionStatuses.NOT_SUPPORTED
      )

      return
    }

    setBrowserNotificationPermissionStatus(Notification.permission)

    const hasShownPromptThisSession = !!JSON.parse(
      sessionStorage.getItem('shown-enable-notifications-prompt')
    )

    if (
      hasShownPromptThisSession ||
      Notification.permission ===
        browserNotificationPermissionStatuses.GRANTED ||
      Notification.permission === browserNotificationPermissionStatuses.DENIED
    ) {
      return
    }

    setShouldShowEnableNotificationsPrompt(true)
    sessionStorage.setItem('shown-enable-notifications-prompt', true)
  }, [setBrowserNotificationPermissionStatus])

  const requestNotificationPermission = async () =>
    Notification.requestPermission().then(permission => {
      setBrowserNotificationPermissionStatus(permission)
      setShouldShowEnableNotificationsPrompt(false)
    })

  return (
    signedInAs && (
      <Header
        closeWhatsNewNotification={closeWhatsNewNotification}
        hasUnseenNews={hasUnseenNews}
        isUserSSO={isUserSSO}
        onCancelEnableNotificationsPrompt={onCancelEnableNotificationsPrompt}
        onConfirmEnableNotificationsPrompt={requestNotificationPermission}
        onNotificationsButtonClicked={toggleNotificationsBarOpen}
        shouldShowPortCallNotifications={shouldShowPortCallNotifications}
        shouldShowQuickSearch={userRole !== userRoles.SHIP}
        shouldShowWhatsNewNotification={shouldShowWhatsNewNotification}
        signOut={signOut}
        signedInAs={signedInAs}
        totalNoOfNewNotifications={totalNoOfNewNotifications}
        shouldShowEnableNotificationsPrompt={
          shouldShowEnableNotificationsPrompt
        }
      />
    )
  )
}

HeaderContainer.defaultProps = {
  signedInAs: null,
  userRole: null,
}

HeaderContainer.propTypes = {
  isNotificationsBarOpen: PropTypes.bool.isRequired,
  isUserSSO: PropTypes.bool.isRequired,
  requestNotifications: PropTypes.func.isRequired,
  setBrowserNotificationPermissionStatus: PropTypes.func.isRequired,
  setIsNotificationsBarOpen: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  signedInAs: PropTypes.string,
  totalNoOfNewNotifications: PropTypes.number.isRequired,
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  isNotificationsBarOpen: selectors.isNotificationsBarOpenSelector(state),
  isUserSSO: selectors.isUserSSOSelector(state),
  signedInAs: selectors.signedInAsSelector(state),
  totalNoOfNewNotifications: selectors.totalNoOfNewNotificationsSelector(state),
  userRole: selectors.userRoleSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, userActions, notificationsActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
