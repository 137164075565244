import ConnectivityIcon from 'components/ConnectivityIcon'
import Heading from 'components/Heading'
import isEmpty from 'lodash/isEmpty'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import portCallStatuses from 'constants/portCallStatuses'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import ShipLoadingSkeleton from './ShipLoadingSkeleton'

const Ship = ({
  imoNumber,
  isCountryUnknown,
  isLoading,
  isShipOnline,
  msElapsedSinceLastUpdate,
  portCallClosed,
  portCallStatus,
  shipName,
}) => {
  const { t } = useTranslation()

  const { hours, minutes } = msToHoursAndMinutes(msElapsedSinceLastUpdate)
  const days = Math.floor(hours / 24)

  const timeElapsed = prettyPrintHoursAndMinutes({
    andLabel: t('global.time.and'),
    dayLabel: t('global.time.day'),
    days,
    hourLabel: t('global.time.hour'),
    hours,
    minuteLabel: t('global.time.minute'),
    minutes,
  })

  return (
    <div className="general-card__value-container">
      {isLoading ? (
        <ShipLoadingSkeleton />
      ) : (
        <>
          <div className="ship-name__vessel-info">
            <Heading
              className="ship-name__heading"
              fontWeight="bold"
              hasNoMarginBottom
              level={4}
            >
              {shipName}
            </Heading>
            <Tooltip title={t('portCall.general.imoNumber')}>
              <Text isLabel>{`(${imoNumber})`}</Text>
            </Tooltip>
          </div>

          <div className="ship-name__text-container">
            {portCallStatus === portCallStatuses.OPEN ? (
              <>
                <ConnectivityIcon
                  className="ship-name__connection-icon"
                  isOnline={isShipOnline}
                />

                <Text>{`${t('portCall.general.lastUpdate')} ${
                  isEmpty(timeElapsed)
                    ? t('portCall.general.justNow')
                    : `${timeElapsed} ${t('global.time.ago')}`
                }`}</Text>
              </>
            ) : portCallStatus === portCallStatuses.CLOSED ? (
              <Timestamp
                date={portCallClosed}
                dateFormat={timeDateAndYear}
                isUTC={isCountryUnknown}
                title={t('portCall.general.portCallClosed')}
              />
            ) : (
              <Text>{t('portCall.general.interruptedPortCallClosed')}</Text>
            )}
          </div>
        </>
      )}
    </div>
  )
}

Ship.defaultProps = {
  imoNumber: null,
  isShipOnline: null,
  msElapsedSinceLastUpdate: null,
  portCallClosed: null,
  portCallStatus: null,
  shipName: null,
}

Ship.propTypes = {
  imoNumber: PropTypes.number,
  isCountryUnknown: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isShipOnline: PropTypes.bool,
  msElapsedSinceLastUpdate: PropTypes.number,
  portCallClosed: PropTypes.string,
  portCallStatus: PropTypes.oneOf([
    portCallStatuses.OPEN,
    portCallStatuses.CLOSED,
    portCallStatuses.INTERRUPTED,
  ]),
  shipName: PropTypes.string,
}

export default Ship
