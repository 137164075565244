import { put, select, takeLatest } from 'redux-saga/effects'

import * as settingsActions from './actions'
import * as settingsSelectors from './selectors'
import actionTypes from './actionTypes'
import restUtils from '../restUtils'

const endpoints = {
  principalDataGet: 'principal/data',
  principalDataPost: 'principal/email-preferences',
}

function* requestEmailNotificationsPreferences() {
  yield restUtils.get({
    endpoint: `${endpoints.principalDataGet}`,
    setRequestSuccessful:
      settingsActions.emailNotificationsPreferencesRequestSuccessful,
    setRequestingState:
      settingsActions.setRequestingEmailNotificationsPreferences,
  })
}

function* saveEmailNotificationsPreferences(action) {
  const { payload } = action

  const currentEmailPreferences = yield select(
    settingsSelectors.emailNotificationsPreferencesSelector
  )

  yield put(
    settingsActions.emailNotificationsPreferencesSaveSuccessful(payload)
  )

  const response = yield restUtils.post({
    content: payload,
    endpoint: `${endpoints.principalDataPost}`,
    setRequestingState:
      settingsActions.setRequestingSaveEmailNotificationsPreferences,
  })

  // undo change to state when request fails
  // error handled by restUtils.post method
  if (!response || (response && !response.ok)) {
    yield put(
      settingsActions.emailNotificationsPreferencesSaveSuccessful(
        currentEmailPreferences
      )
    )
  }
}

export function* watchRequestEmailNotificationsPreferences() {
  yield takeLatest(
    actionTypes.REQUEST_EMAIL_NOTIFICATIONS_PREFERENCES,
    requestEmailNotificationsPreferences
  )
}

export function* watchSaveEmailNotificationsPreferences() {
  yield takeLatest(
    actionTypes.SAVE_EMAIL_NOTIFICATIONS_PREFERENCES,
    saveEmailNotificationsPreferences
  )
}
