import Heading from 'components/Heading'
import React from 'react'

import bayExplanation from './Images/bay-explanation.png'
import bayplan from './Images/bayplan.png'
import bayplanSelected from './Images/bayplan-selected.png'
import bayplanWorkStarted from './Images/bayplan-work-started.png'

const LoggingContainerMoves = () => (
  <div>
    <Heading level={2}>Logging container moves</Heading>

    <p>Once a gantry is active on a bay, container moves can be recorded.</p>
    <p>Press the bay to move to the bay screen.</p>
    <img src={bayplan} />

    <p>The following diagram shows the available information for each bay.</p>
    <img src={bayExplanation} />

    <p>
      To mark containers as discharged or loaded, press on either the Deck or
      Hold moves. In this example, we will be logging a discharge move from the
      deck.
    </p>
    <img src={bayplanSelected} />
    <p>
      Logged containers are marked in green with a tick, and remaining
      containers are coloured blue
    </p>

    <p>Container moves can be selected as follows:</p>
    <ul>
      <li>Individually, by pressing each container</li>
      <li>Multiple, by pressing and dragging across multiple containers</li>
      <li>By row, by pressing the row numbers above and below the bay</li>
      <li>
        By tier, by pressing the tier numbers on the left and right of the bay
      </li>
    </ul>
    <p>
      A mini map is at the bottom left of the view showing a high-level view of
      logged and remaining containers.
    </p>
    <p>Once the container move has been selected, press the ‘Log’ button.</p>

    <img src={bayplanWorkStarted} />
    <p>
      If it is the first work to be logged on a bay, Cargo mate will request the
      start time.
    </p>
    <p>
      Enter a time for the work and press ‘Ok’. The gantry will now be shown to
      be working on the bay on the Cargo Plan.
    </p>
  </div>
)

export default LoggingContainerMoves
