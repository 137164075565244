import Heading from 'components/Heading'
import React from 'react'

const MovingAGantryToAnotherBay = () => (
  <div>
    <Heading level={2}>Moving a gantry to another bay</Heading>

    <p>Moving a gantry from one bay to another is simple.</p>
    <ol>
      <li>
        On the new bay, press and view the bay, then press on ‘Add Gantry’
      </li>
      <li>Press ‘Select gantry’ on the ‘Add Gantry’ screen</li>
      <li>Choose the gantry from a previous bay</li>
      <li>Enter the time that it started work on the new bay</li>
    </ol>

    <p>
      Please ensure that before moving a gantry from one bay to another, that
      you update the current number of moves on the bay the gantry is moving
      from. This will prevent any difficulties with gantry productivity figures.
    </p>
  </div>
)

export default MovingAGantryToAnotherBay
