export default {
  activePortCall: 'active-port-call',
  addingGantries: 'adding-gantries',
  bapliesAndRestows: 'baplies-and-restows',
  bestPractices: 'best-practices',
  cancellingContainers: 'cancelling-containers',
  cargoEvents: 'cargo-events',
  cargoMateDashboardDocs: 'cargomate-dashboard-docs',
  cargoMateDevice: 'cargomate-device',
  cargoMateHomeScreen: 'cargomate-home-screen',
  cargoMateMobileDocs: 'cargomate-mobile-docs',
  cargoPlan: 'cargo-plan',
  containerInfo: 'container-info',
  containerSearch: 'container-search',
  dismissingAGantry: 'dismissing-a-gantry',
  endPortCall: 'end-port-call',
  feedback: 'feedback',
  historicPortCalls: 'historic-port-calls',
  home: 'home',
  learnMore: 'learn-more',
  logLashingAndTwistlocks: 'log-lashing-and-twistlocks',
  logbookEvents: 'logbook-events',
  loggingContainerMoves: 'logging-container-moves',
  mobileDashboard: 'mobile-dashboard',
  movingAGantryToAnotherBay: 'moving-a-gantry-to-another-bay',
  operationalConsiderations: 'operational-considerations',
  portCallNotClosed: 'port-call-not-closed',
  progressScreen: 'progress-screen',
  startEditPortCall: 'start-edit-port-call',
  tempStopAllGantries: 'temp-stop-all-gantries',
  tempStopSingleGantry: 'temp-stop-single-gantry',
  updatingScheduledDeparture: 'updating-scheduled-departure',
  vesselArrivalInfo: 'vessel-arrival-info',
  vesselDepartureInfo: 'vessel-departure-info',
  webDashboardOverview: 'web-dashboard-overview',
}
