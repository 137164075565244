import { Button, Modal } from 'antd'
import first from 'lodash/first'
import Heading from 'components/Heading'
import isEmpty from 'lodash/isEmpty'
import news from 'global/news'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import uniq from 'lodash/uniq'
import { useTranslation } from 'react-i18next'

import './whatsNewNotification.less'

const WhatsNewNotification = ({ isVisible, onCancel, onSeeMore }) => {
  const { t } = useTranslation()

  const newsToShow = first(news)

  if (!isVisible || isEmpty(newsToShow)) {
    return
  }

  const handleCancel = () => {
    const seenNewsIds = JSON.parse(localStorage.getItem('news-id-seen')) || []

    localStorage.setItem(
      'news-id-seen',
      JSON.stringify(uniq([...seenNewsIds, newsToShow.id]))
    )

    onCancel()
  }

  return (
    <Modal
      onCancel={handleCancel}
      open={false} // TEMPORARY SET TO FALSE TO HIDE THE MODAL (https://github.com/cargomate/cargomate-web-dashboard-v3/issues/888)
      footer={[
        <Button key="whats-new-button" onClick={onSeeMore} type="primary">
          {t('whatsNewNotification.seeMore')}
        </Button>,
      ]}
    >
      <div className="whats-new-notification__container">
        <Text>
          {newsToShow.version
            ? `${t('whatsNewNotification.whatsNewInVersion')} ${
                newsToShow.version
              }`
            : t('whatsNewNotification.whatsNew')}
        </Text>

        <Heading level={3}>{newsToShow.title}</Heading>

        <Text>{newsToShow.body}</Text>
      </div>
    </Modal>
  )
}

WhatsNewNotification.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSeeMore: PropTypes.func.isRequired,
}

export default WhatsNewNotification
