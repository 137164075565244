import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

import cargoPlan from './Images/cargo-plan.png'
import cargoPlanArrows from './Images/cargo-plan-arrows.png'

const CargoPlan = ({ setPage }) => {
  const navigateToAddingGantries = () => setPage(userManualPages.addingGantries)

  const navigateToMovingAGantry = () =>
    setPage(userManualPages.movingAGantryToAnotherBay)

  const navigateToDismissingAGantry = () =>
    setPage(userManualPages.dismissingAGantry)

  const navigateToTempStopSingleGantry = () =>
    setPage(userManualPages.tempStopSingleGantry)

  const navigateToTempStopAllGantries = () =>
    setPage(userManualPages.tempStopAllGantries)

  const navigateToLoggingContainerMoves = () =>
    setPage(userManualPages.loggingContainerMoves)

  const navigateToCancellingContainer = () =>
    setPage(userManualPages.cancellingContainers)

  const navigateToLogLashingAndTwistlocks = () =>
    setPage(userManualPages.logLashingAndTwistlocks)

  const navigateToContainerSearch = () =>
    setPage(userManualPages.containerSearch)

  const navigateToContainerInfo = () => setPage(userManualPages.containerInfo)

  return (
    <div>
      <Heading level={2}>Cargo Plan</Heading>
      <ul>
        <li>
          <a onClick={navigateToAddingGantries}>Adding gantries</a>
        </li>
        <li>
          <a onClick={navigateToMovingAGantry}>
            Moving a gantry to another bay
          </a>
        </li>
        <li>
          <a onClick={navigateToDismissingAGantry}>Dismissing a gantry</a>
        </li>
        <li>
          <a onClick={navigateToTempStopSingleGantry}>
            Temporary stop a single gantry
          </a>
        </li>
        <li>
          <a onClick={navigateToTempStopAllGantries}>
            Temporary stop all gantries
          </a>
        </li>
        <li>
          <a onClick={navigateToLoggingContainerMoves}>
            Logging container moves
          </a>
        </li>
        <li>
          <a onClick={navigateToCancellingContainer}>
            Cancelling containers (’Cut’ containers)
          </a>
        </li>
        <li>
          <a onClick={navigateToLogLashingAndTwistlocks}>
            Logging lashing and twistlock checks
          </a>
        </li>
        <li>
          <a onClick={navigateToContainerSearch}>
            Container Search by Container ID / Location
          </a>
        </li>
        <li>
          <a onClick={navigateToContainerInfo}>
            Container Information by Bay Plan
          </a>
        </li>
      </ul>
      <img src={cargoPlan} />
      <p>
        The ‘Cargo plan’ screen combines many cargo operations monitoring tasks
        such as bayplans, gantry logging, lashing and twistlock checklists and
        container searches in one view.
      </p>
      <p>The following diagram shows how the information is presented:</p>
      <img src={cargoPlanArrows} />
    </div>
  )
}

CargoPlan.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default CargoPlan
