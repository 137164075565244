import portCallStatuses from 'constants/portCallStatuses'
import PropTypes from 'prop-types'

const portCallStatus = PropTypes.oneOf([
  portCallStatuses.CLOSED,
  portCallStatuses.INTERRUPTED,
  portCallStatuses.OPEN,
])

export default PropTypes.shape({
  imoNumber: PropTypes.number.isRequired,
  isShipOnline: PropTypes.bool.isRequired,
  msElapsedSinceLastUpdate: PropTypes.number,
  portCallClosed: PropTypes.string,
  portCallStatus: portCallStatus.isRequired,
  portCountry: PropTypes.string.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
  shipName: PropTypes.string.isRequired,
  terminal: PropTypes.string,
  voyageNumber: PropTypes.string.isRequired,
})
