import { createSelector } from '@reduxjs/toolkit'

const userSelector = state => state.user

export const signedInAsSelector = createSelector(
  userSelector,
  user => user.signedInAs
)

export const isSigningInSelector = createSelector(
  userSelector,
  user => user.isSigningIn
)

export const isUserSSOSelector = createSelector(
  userSelector,
  user => user.isUserSSO
)

export const signInErrorSelector = createSelector(
  userSelector,
  user => user.signInError
)

export const userRoleSelector = createSelector(userSelector, user => user.role)

export const userImoNumberSelector = createSelector(
  userSelector,
  user => user.imoNumber
)

export const cognitoGroupSelector = createSelector(
  userSelector,
  user => user.cognitoGroup
)
