import colors from 'global/styles/colors'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'
import { WifiOutlined } from '@ant-design/icons'

import './connectivityIcon.less'

const ConnectivityIcon = ({ className, isOnline }) => {
  const { t } = useTranslation()

  const onlinePopoverContent = (
    <Text>
      <span className="connectivity-icon__status connectivity-icon__status--online">
        {t('connectivityIcon.online')}
      </span>
      {` - ${t('connectivityIcon.onlineDescription')}`}
    </Text>
  )

  const offlinePopoverContent = (
    <Text>
      <span className="connectivity-icon__status">
        {t('connectivityIcon.offline')}
      </span>
      {` - ${t('connectivityIcon.offlineDescription')}`}
    </Text>
  )

  return (
    <div className={className}>
      <Popover
        content={isOnline ? onlinePopoverContent : offlinePopoverContent}
      >
        <WifiOutlined
          style={{ color: isOnline ? colors.colorSuccess : colors.colorGray }}
        />
      </Popover>
    </div>
  )
}

ConnectivityIcon.defaultProps = {
  className: null,
}

ConnectivityIcon.propTypes = {
  className: PropTypes.string,
  isOnline: PropTypes.bool,
}

export default ConnectivityIcon
