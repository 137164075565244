import userRoles from 'constants/userRoles'

/*
 * News
 *
 * Add another object to display it on the `What's New` page.
 * Place the accompanying image in `src/assets/images`.
 *
 * Arrange items by newest first.
 */

const news = [
  {
    body: 'Your pinned ports and vessels were reset, but will now save to your account and sync across devices.',
    dateAdded: 'March 28, 2023',
    id: '4f86eb29-e2f8-49d8-9362-7d17ace310d1',
    image: 'desktop-mobile.png',
    title: 'Pinned ports and vessels sync across devices',
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER],
    version: '3.0.73',
  },
]

export default news
