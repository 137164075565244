import { Collapse } from 'antd'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './questionsAndAnswers.less'

const QuestionsAndAnswers = ({ faqs, customContent }) => {
  const { Panel } = Collapse

  const renderHeader = question => (
    <Heading hasNoMarginBottom isDark level={4}>
      {question}
    </Heading>
  )

  return (
    <Collapse
      bordered={false}
      className="questions-and-answers__collapse-container"
      expandIconPosition="end"
    >
      {faqs ? (
        faqs.map(faq => (
          <Panel
            className="questions-and-answers__panel-header"
            header={renderHeader(faq.question)}
            key={faq.question}
          >
            <div className="questions-and-answers__panel-content">
              <Text>{faq.answer}</Text>
            </div>
          </Panel>
        ))
      ) : (
        <Panel
          className="questions-and-answers__panel-header"
          header={renderHeader(customContent.question)}
          key={customContent.question}
        >
          <div className="questions-and-answers__panel-content">
            {customContent.answer}
          </div>
        </Panel>
      )}
    </Collapse>
  )
}

QuestionsAndAnswers.defaultProps = {
  customContent: null,
  faqs: null,
}

QuestionsAndAnswers.propTypes = {
  customContent: PropTypes.shape({
    answer: PropTypes.element.isRequired,
    question: PropTypes.string.isRequired,
  }),
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
    })
  ),
}

export default QuestionsAndAnswers
