import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import { Button } from 'antd'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './allowNotificationsCard.less'

const AllowNotificationsCard = ({ onConfirm }) => {
  const { t } = useTranslation()

  const areNotificationPermissionDenied =
    Notification.permission === browserNotificationPermissionStatuses.DENIED

  const headingText = areNotificationPermissionDenied
    ? t('allowNotificationsCard.notificationsPermissionDenied')
    : t('allowNotificationsCard.enablePushNotifications')

  const bodyText = areNotificationPermissionDenied
    ? t('allowNotificationsCard.howToEnableNotifications')
    : t('allowNotificationsCard.allowNotificationsToGetUpdates')

  const buttonText = areNotificationPermissionDenied
    ? t('allowNotificationsCard.getHelp')
    : t('allowNotificationsCard.allowNotifications')

  return (
    <div className="enable-notifications-card__container">
      <Heading level={4}>{headingText}</Heading>
      <Text>{bodyText}</Text>
      <Button
        className="enable-notifications-card__button"
        onClick={onConfirm}
        type="primary"
      >
        {buttonText}
      </Button>
    </div>
  )
}

AllowNotificationsCard.propTypes = {
  onConfirm: PropTypes.func.isRequired,
}

export default AllowNotificationsCard
