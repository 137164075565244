import Card from 'components/Card'
import colors from 'global/styles/colors'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import paths from 'constants/paths'
import portCallStatuses from 'constants/portCallStatuses'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import Text from 'components/Text'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import workPlannedStatuses from 'constants/workPlannedStatuses'

import './milestones.less'
import MilestoneStep from './MilestoneStep'
import MoreInfoContent from './MoreInfoContent'

const Milestones = ({
  isCountryUnknown,
  isLoading,
  portCallStatus,
  milestones,
}) => {
  const { t } = useTranslation()
  const { tab } = useParams()

  const wasPortCallInterrupted = portCallStatus === portCallStatuses.INTERRUPTED

  const { WORK_PLANNED } = workPlannedStatuses

  const renderCargoOpsCompleteStep = () => {
    if (!milestones) {
      return
    }

    const { loggedAt } = milestones.cargoOperationsComplete
    const isCargoOpsCompleteLogged = !!loggedAt

    const { msFinishedDiffToSchedule = null, status = null } =
      milestones.cargoOperationsComplete.finishedDiffToSchedule || {}

    const cargoOpsCompleteStatusColors = {
      [scheduleStatuses.AHEAD]: colors.colorSuccess,
      [scheduleStatuses.BEHIND]: colors.colorDanger,
      [scheduleStatuses.ON_SCHEDULE]: colors.colorGrayDark2,
    }

    const cargoOpsCompleteStatusColor = isCargoOpsCompleteLogged
      ? cargoOpsCompleteStatusColors[status]
      : null

    const timeLabel = msFinishedDiffToSchedule =>
      prettyPrintHoursAndMinutes({
        ...msToHoursAndMinutes(msFinishedDiffToSchedule),
        hourLabel: t('global.time.hourShort'),
        isShortLabel: true,
        minuteLabel: t('global.time.minuteShort'),
      })

    const finishedLabel =
      status === scheduleStatuses.ON_SCHEDULE ||
      status === scheduleStatuses.AHEAD
        ? t('portCall.milestones.ahead')
        : t('portCall.milestones.behind')

    const renderFinishedLabels = (
      <>
        <Text
          isBold
          style={{ color: cargoOpsCompleteStatusColor }}
        >{`${timeLabel(msFinishedDiffToSchedule)} ${finishedLabel}`}</Text>
        <Text style={{ color: cargoOpsCompleteStatusColor }}>
          {status !== scheduleStatuses.BEHIND
            ? `${t('portCall.milestones.of')} ${t(
                'portCall.milestones.scheduledDeparture'
              )}`
            : t('portCall.milestones.scheduledDeparture')}
        </Text>
      </>
    )

    return (
      <MilestoneStep
        hasMarginBottom={isCargoOpsCompleteLogged}
        hasNoDivider={!isCargoOpsCompleteLogged}
        isCompleted={isCargoOpsCompleteLogged}
        isCountryUnknown={isCountryUnknown}
        isLoading={isLoading}
        isMissing={wasPortCallInterrupted && !isCargoOpsCompleteLogged}
        title={t('portCall.milestones.cargoOperationsComplete')}
        timestamp={
          isCargoOpsCompleteLogged
            ? milestones.cargoOperationsComplete.loggedAt
            : null
        }
      >
        {isCargoOpsCompleteLogged && renderFinishedLabels}
      </MilestoneStep>
    )
  }

  const renderGearboxesOnboardStep = () => {
    if (!(milestones && milestones.gearboxes)) {
      return
    }

    const { numberOfGearboxes, numberOnboard } = milestones.gearboxes

    const isLocationInfoMissing = !numberOfGearboxes

    const areAllGearboxesOnboard =
      !isLocationInfoMissing && numberOfGearboxes === numberOnboard

    const renderGearboxLocations = isLocationInfoMissing
      ? t('portCall.milestones.gearboxInfoMissing')
      : `${numberOnboard} ${t(
          'portCall.milestones.of'
        )} ${numberOfGearboxes} ${t('portCall.milestones.onboard')}`

    const route = !isLocationInfoMissing
      ? location.pathname.replace(
          `/${tab}`,
          `/${paths.portCall.shipOverview}#${paths.portCall.gearboxLocations}`
        )
      : null

    return (
      <MilestoneStep
        hasTimestamp={false}
        isCompleted={areAllGearboxesOnboard}
        isCountryUnknown={isCountryUnknown}
        isDisabled={isLocationInfoMissing}
        isLoading={isLoading}
        isMissing={wasPortCallInterrupted}
        label={renderGearboxLocations}
        route={route}
        title={t('portCall.milestones.gearboxesOnboard')}
      />
    )
  }

  const isLoadingPlanned =
    milestones && milestones.loadStarted.status === WORK_PLANNED

  const isDischargePlanned =
    milestones && milestones.dischargeStarted.status === WORK_PLANNED

  const isGangwayRiggedAndCheckedLogged =
    milestones && !!milestones.gangwayRiggedAndChecked.loggedAt

  const isCargoOpsCommenceLogged =
    milestones && !!milestones.cargoOperationsCommence.loggedAt

  const isDischargeCompleteLogged =
    milestones && !!milestones.dischargeComplete.loggedAt

  const isDischargeStartedLogged =
    milestones && !!milestones.dischargeStarted.loggedAt

  const isLoadCompleteLogged = milestones && !!milestones.loadComplete.loggedAt

  const isLoadStartedLogged = milestones && !!milestones.loadStarted.loggedAt

  const isVesselReadyForOperation =
    milestones && !!milestones.vesselReadyForOperation.loggedAt

  const renderDischargeSteps = () => (
    <>
      <MilestoneStep
        hasNoDivider={!isDischargePlanned && !isDischargeStartedLogged}
        isCompleted={isDischargeStartedLogged}
        isCountryUnknown={isCountryUnknown}
        isDisabled={!isDischargePlanned && !isDischargeStartedLogged}
        isLoading={isLoading}
        timestamp={milestones ? milestones.dischargeStarted.loggedAt : null}
        title={t('portCall.milestones.dischargeStarted')}
        isMissing={
          wasPortCallInterrupted &&
          !isDischargeStartedLogged &&
          isDischargePlanned
        }
        label={
          !isDischargePlanned && !isDischargeStartedLogged
            ? t('portCall.milestones.noDischargePlanned')
            : null
        }
      />
      {(isDischargePlanned || isDischargeStartedLogged) && (
        <MilestoneStep
          hasNoDivider
          isCompleted={isDischargeCompleteLogged}
          isCountryUnknown={isCountryUnknown}
          isDisabled={!isDischargePlanned && !isDischargeStartedLogged}
          isLoading={isLoading}
          timestamp={milestones ? milestones.dischargeComplete.loggedAt : null}
          title={t('portCall.milestones.dischargeComplete')}
          isMissing={
            wasPortCallInterrupted &&
            !isDischargeCompleteLogged &&
            isDischargePlanned
          }
        />
      )}
    </>
  )

  const renderLoadSteps = () => (
    <>
      <MilestoneStep
        hasNoDivider={!isLoadingPlanned && !isLoadStartedLogged}
        isCompleted={isLoadStartedLogged}
        isCountryUnknown={isCountryUnknown}
        isDisabled={!isLoadingPlanned && !isLoadStartedLogged}
        isLoading={isLoading}
        timestamp={milestones ? milestones.loadStarted.loggedAt : null}
        title={t('portCall.milestones.loadStarted')}
        isMissing={
          wasPortCallInterrupted && !isLoadStartedLogged && isLoadingPlanned
        }
        label={
          !isLoadingPlanned && !isLoadStartedLogged
            ? t('portCall.milestones.noLoadPlanned')
            : null
        }
      />
      {(isLoadingPlanned || isLoadStartedLogged) && (
        <MilestoneStep
          hasNoDivider
          isCompleted={isLoadCompleteLogged}
          isCountryUnknown={isCountryUnknown}
          isDisabled={!isLoadingPlanned && !isLoadStartedLogged}
          isLoading={isLoading}
          timestamp={milestones ? milestones.loadComplete.loggedAt : null}
          title={t('portCall.milestones.loadComplete')}
          isMissing={
            wasPortCallInterrupted && !isLoadCompleteLogged && isLoadingPlanned
          }
        />
      )}
    </>
  )

  return (
    <Card
      className="milestones__card"
      moreInfoTitle={t('portCall.milestones.milestones')}
      title={t('portCall.milestones.milestones')}
      moreInfoContent={
        <MoreInfoContent wasPortCallInterrupted={wasPortCallInterrupted} />
      }
    >
      <div className="milestones__container">
        <MilestoneStep
          isCompleted={isGangwayRiggedAndCheckedLogged}
          isCountryUnknown={isCountryUnknown}
          isLoading={isLoading}
          isMissing={wasPortCallInterrupted && !isGangwayRiggedAndCheckedLogged}
          title={t('portCall.milestones.gangwayRiggedAndChecked')}
          timestamp={
            milestones ? milestones.gangwayRiggedAndChecked.loggedAt : null
          }
        />

        <MilestoneStep
          isCompleted={isVesselReadyForOperation}
          isCountryUnknown={isCountryUnknown}
          isLoading={isLoading}
          isMissing={wasPortCallInterrupted && !isVesselReadyForOperation}
          title={t('portCall.milestones.vesselReadyForOperation')}
          timestamp={
            milestones ? milestones.vesselReadyForOperation.loggedAt : null
          }
        />

        <MilestoneStep
          isCompleted={isCargoOpsCommenceLogged}
          isCountryUnknown={isCountryUnknown}
          isLoading={isLoading}
          isMissing={wasPortCallInterrupted && !isCargoOpsCommenceLogged}
          title={t('portCall.milestones.cargoOperationsCommence')}
          timestamp={
            milestones ? milestones.cargoOperationsCommence.loggedAt : null
          }
        >
          {renderDischargeSteps()}
          {renderLoadSteps()}
        </MilestoneStep>

        {renderGearboxesOnboardStep()}

        {renderCargoOpsCompleteStep()}
      </div>
    </Card>
  )
}

Milestones.defaultProps = {
  baplieInfoStatus: null,
  portCallStatus: null,
}

Milestones.propTypes = {
  baplieInfoStatus: propTypes.baplieInfoStatus,
  isCountryUnknown: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  milestones: propTypes.milestones.isRequired,
  portCallStatus: PropTypes.oneOf([
    portCallStatuses.CLOSED,
    portCallStatuses.INTERRUPTED,
    portCallStatuses.OPEN,
  ]),
}

export default Milestones
