import Heading from 'components/Heading'
import React from 'react'

import addedBaplies from './Images/added-baplies.png'
import allBapliesOk from './Images/all-baplies-ok.png'
import bapliesAndRestows from './Images/baplies-and-restows.png'
import restows from './Images/restows.png'

const BapliesAndRestows = () => (
  <div>
    <Heading level={2}>Baplies and restows</Heading>

    <p>
      Before uploading baplie files to the CargoMate device, the correct baplies
      and restow files for the port call need to be copied onto the USB stick.
    </p>
    <p>
      Baplie files can be updated during a port call. Restow files{' '}
      <strong>cannot</strong> be updated during a port call.
    </p>

    <Heading isDark level={3}>
      Baplie files - Best practices
    </Heading>
    <ul>
      <li>
        <strong>Only Baplie Version 2.2 is supported</strong>
      </li>
      <li>Any non-industry standard data will not be used in CargoMate</li>
      <li>Baplie files should not be stored in a folder on the USB stick</li>
      <li>
        It is best to export baplies from the cargo planning software onboard
        rather than using terminal baplies directly in CargoMate
      </li>
      <li>Delete old baplie files from the USB stick after use</li>
    </ul>

    <Heading hasNoMarginBottom isDark level={4}>
      Sections
    </Heading>
    <ul>
      <li>
        <a href="#preparing-usb-and-copying-baplies">
          Preparing the USB stick and copying baplies
        </a>
      </li>
      <li>
        <a href="#baplies-added">Baplies added</a>
      </li>
      <li>
        <a href="#updating-baplies">Updating baplies</a>
      </li>
      <li>
        <a href="#further-baplie-help">Further baplie help</a>
      </li>
      <li>
        <a href="#restows">Restows</a>
      </li>
    </ul>

    <Heading id="preparing-usb-and-copying-baplies" isDark level={3}>
      Preparing the USB stick and copying baplies
    </Heading>
    <img src={bapliesAndRestows} />
    <ol>
      <li>
        Ensure the USB stick is formatted as FAT32 and delete any previous
        baplies or other files
      </li>
      <li>Copy a discharge baplie and/or load baplie onto the USB stick</li>
      <li>Connect the USB stick to the USB OTG device</li>
      <li>Press ‘Add discharge baplie’</li>
      <li>
        When prompted, plug in the USB OTG device to the USB port on the
        CargoMate device
      </li>
      <li>Choose the discharge baplie file</li>
      <li>Unplug the USB OTG device</li>
      <li>CargoMate will process the baplie</li>
      <li>Select the port you want to use containers for</li>
      <li>Repeat steps 4 - 9 for the ‘Add load baplie’</li>
      <img src={allBapliesOk} />
    </ol>

    <Heading id="baplies-added" isDark level={3}>
      Baplies added
    </Heading>
    <p>
      Once discharge and/or load containers have been selected, the screen looks
      likes the image below:
    </p>
    <img src={addedBaplies} />
    <p>
      Note: The number of containers for the port call are only shown in this
      screen. CargoMate calculates the least number of moves which{' '}
      <strong>may not</strong> equal the number of containers. For more
      information on this, please refer to ‘Calculating Moves and accounting for
      Spreaders’.
    </p>

    <Heading id="updating-baplies" isDark level={3}>
      Updating baplies
    </Heading>
    <p>Updating a baplie is exactly the same process as adding a baplie.</p>
    <p>
      Both discharge and load baplies can be updated as many times as necessary
      during cargo operations.
    </p>

    <Heading id="further-baplie-help" isDark level={3}>
      Further Baplie help
    </Heading>
    <p>
      You can press the ‘?’ button at the top right of the Baplie files screen
      to access relevant offline tutorial videos for processing baplie files.
    </p>

    <Heading id="restows" isDark level={3}>
      Restows
    </Heading>
    <p>
      Unfortunately, baplies do not contain all the relevant information to
      determine the number and location of restows. As such, a special restow
      file is required to be made onboard.
    </p>
    <p>
      The restow file has to be created onboard. Restows are sent to the vessel
      by the planner. The contents of this email shall be copied to a new text
      file, and then copied onto the USB stick to upload. Further information
      for creating a restow file and adding restows to the device can be found
      in the tutorial video ‘Adding Restows’.
    </p>
    <p>
      Once restows have been added successfully, you should see the number of
      containers and the number of restow moves, along with the locations of the
      restows.
    </p>
    <img src={restows} />
    <p>
      If the restow file contains errors or the restows are not found in the
      baplies, the restow screen will show an error message and a list showing
      the erroneous restows will be available. This list can be used to fix the
      errors, then reset and reupload the correct restow file to the device.
    </p>
  </div>
)

export default BapliesAndRestows
