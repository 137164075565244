import Heading from 'components/Heading'
import React from 'react'

import editScheduledDepartureTime from './Images/edit-scheduled-departure-time.png'

const UpdatingScheduledDeparture = () => (
  <div>
    <Heading level={2}>Updating Scheduled Departure Time</Heading>

    <img src={editScheduledDepartureTime} />
    <p>
      If the vessel is notified of changes to scheduled departure time, such as
      by the agent or the terminal, this can be updated by pressing the ‘Edit’
      button.
    </p>
    <p>
      As the estimated completion time is compared against the scheduled
      departure time in the Performance tab, a non-updated departure time can
      have an effect on whether CargoMate is forecasting an ‘early’, ‘on-time’
      or ‘delayed’ completion time.
    </p>
  </div>
)

export default UpdatingScheduledDeparture
