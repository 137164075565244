import { CalendarOutlined } from '@ant-design/icons'
import Card from 'components/Card'
import { ReactComponent as FlagCheckered } from 'assets/icons/flag-checkered.svg'
import { ReactComponent as Gantry } from 'assets/icons/gantry.svg'
import Heading from 'components/Heading'
import IconValueAndLabel from 'components/IconValueAndLabel'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import { ReactComponent as StopwatchIcon } from 'assets/icons/stopwatch.svg'
import Text from 'components/Text'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import './containerMovesProgress.less'
import MoreInfoContent from './MoreInfoContent'
import RemainingMoves from '../RemainingMoves'
import ScheduleGauge from '../ScheduleGauge'

const ContainerMovesProgress = ({ containerMovesProgress, isLoading }) => {
  const { t } = useTranslation()

  if (!containerMovesProgress) {
    return (
      <Card title={t('portCall.containerMovesProgress.containerMovesProgress')}>
        <Text>{t('portCall.containerMovesProgress.noData')}</Text>
      </Card>
    )
  }

  const { totalPlannedContainers, productivityOverview } =
    containerMovesProgress

  const getDurationText = durationInMs =>
    prettyPrintHoursAndMinutes({
      ...msToHoursAndMinutes(durationInMs),
      hourLabel: t('global.time.hourShort'),
      isShortLabel: true,
      minuteLabel: t('global.time.minuteShort'),
    })

  const hasEstimatedTimeUntilCompletion =
    !!productivityOverview.cargoOperationsComplete.estimatedMsToCompletion
  const timeUntilCompletionText = hasEstimatedTimeUntilCompletion
    ? productivityOverview.cargoOperationsComplete.estimatedMsToCompletion < 0
      ? t('portCall.containerMovesProgress.due')
      : getDurationText(
          productivityOverview.cargoOperationsComplete.estimatedMsToCompletion
        )
    : t('portCall.containerMovesProgress.estimationNotAvailable')

  const hasEstimatedTimeDiffToScheduledDeparture =
    !!productivityOverview.cargoOperationsComplete.estimatedDiffToSchedule

  const isCompletionBehindScheduleDeparture =
    scheduleStatuses.BEHIND === productivityOverview.status

  const isCompletionAheadOfScheduleDeparture = [
    scheduleStatuses.AHEAD,
    scheduleStatuses.ON_SCHEDULE,
  ].includes(productivityOverview.status)

  const timeDiffToScheduledDepartureText =
    hasEstimatedTimeDiffToScheduledDeparture
      ? `${getDurationText(
          productivityOverview.cargoOperationsComplete.estimatedDiffToSchedule
        )} ${
          isCompletionBehindScheduleDeparture
            ? t('portCall.containerMovesProgress.behind')
            : t('portCall.containerMovesProgress.aheadOf')
        }`
      : t('portCall.containerMovesProgress.estimationNotAvailable')

  const hasGantriesAdded =
    !!productivityOverview.gantriesWorking.totalNoOfGantries
  const gantriesWorkingText = hasGantriesAdded
    ? `${productivityOverview.gantriesWorking.noWorking} ${t(
        'portCall.containerMovesProgress.of'
      )} ${productivityOverview.gantriesWorking.totalNoOfGantries}`
    : t('portCall.containerMovesProgress.noGantriesWorking')

  const hasAvgMovesValue = !!productivityOverview.totalAverageMovesPerHour
  const avgMovesPerHourText = hasAvgMovesValue
    ? productivityOverview.totalAverageMovesPerHour
    : t('portCall.containerMovesProgress.dataNotAvailable')

  const hasRestowContainers = !!containerMovesProgress.totalRestowContainers
  const totalContainers = hasRestowContainers
    ? containerMovesProgress.totalRestowContainers.totalNoOfRestowContainers +
      totalPlannedContainers.totalNoOfContainers
    : totalPlannedContainers.totalNoOfContainers
  const totalRemainingContainers = hasRestowContainers
    ? containerMovesProgress.totalRestowContainers
        .remainingNoOfRestowContainers +
      totalPlannedContainers.remainingNoOfContainers
    : totalPlannedContainers.remainingNoOfContainers

  const shouldShowScheduleGaugeTooltip =
    hasEstimatedTimeUntilCompletion && !isLoading

  return (
    <Card
      className="container-moves-progress__card"
      moreInfoContent={<MoreInfoContent />}
      title={t('portCall.containerMovesProgress.containerMovesProgress')}
      moreInfoTitle={t(
        'portCall.containerMovesProgress.containerMovesProgress'
      )}
    >
      <div className="container-moves-progress__container">
        <RemainingMoves
          doesIncludeRestows={hasRestowContainers}
          hasMoves={!!totalContainers}
          isLoading={isLoading}
          noOfMovesRemaining={totalRemainingContainers}
          noOfTotalMoves={totalContainers}
          title={t('portCall.containerMovesProgress.totalContainers')}
        />

        <div className="container-moves-progress__productivity">
          <Heading hasNoMarginBottom isDark level={5}>
            {t('portCall.containerMovesProgress.productivityOverview')}
          </Heading>
          <div className="container-moves-progress__productivity-container">
            <Tooltip
              placement="bottomRight"
              title={
                shouldShowScheduleGaugeTooltip &&
                `${timeDiffToScheduledDepartureText} ${t(
                  'portCall.containerMovesProgress.scheduledDeparture'
                )}`
              }
            >
              <div>
                <ScheduleGauge
                  isLoading={isLoading}
                  status={productivityOverview.status}
                  value={productivityOverview.scheduleGaugeValue}
                />
              </div>
            </Tooltip>
            <div className="container-moves-progress__summary-container">
              <IconValueAndLabel
                iconComponent={CalendarOutlined}
                isLoading={isLoading}
                isSmallValueText={!hasEstimatedTimeDiffToScheduledDeparture}
                label={t('portCall.containerMovesProgress.scheduledDeparture')}
                value={timeDiffToScheduledDepartureText}
                type={
                  isCompletionAheadOfScheduleDeparture
                    ? 'success'
                    : isCompletionBehindScheduleDeparture
                    ? 'warning'
                    : null
                }
              />

              <IconValueAndLabel
                iconComponent={Gantry}
                isLoading={isLoading}
                isSmallValueText={!hasGantriesAdded}
                label={t('portCall.containerMovesProgress.gantriesWorking')}
                value={gantriesWorkingText}
              />

              <IconValueAndLabel
                iconComponent={FlagCheckered}
                isLoading={isLoading}
                isSmallValueText={!hasEstimatedTimeUntilCompletion}
                label={t('portCall.containerMovesProgress.timeUntilCompletion')}
                value={timeUntilCompletionText}
              />

              <IconValueAndLabel
                iconComponent={StopwatchIcon}
                isLoading={isLoading}
                isSmallValueText={!hasAvgMovesValue}
                value={avgMovesPerHourText}
                label={t(
                  'portCall.containerMovesProgress.containerMovesPerHour'
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

ContainerMovesProgress.defaultProps = {
  containerMovesProgress: null,
}

ContainerMovesProgress.propTypes = {
  containerMovesProgress: propTypes.containerMovesProgress,
  isLoading: PropTypes.bool.isRequired,
}

export default ContainerMovesProgress
