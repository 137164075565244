import { Cascader, List, Pagination, Spin } from 'antd'
import React, { useEffect } from 'react'
import {
  SCHEDULED_DEPARTURE,
  SHOW_ALL,
  SHOW_PINNED_PORTS,
  SHOW_PINNED_SHIPS,
} from 'domain/Overview/constants'
import first from 'lodash/first'
import Heading from 'components/Heading'
import Notifications from 'components/Notifications'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './overview.less'
import OverviewCard from './Components/OverviewCard'
import RecentlyCompletedSection from './Components/RecentlyCompletedSection'

const Overview = ({
  activePortCalls,
  activePortCallsFilterBy,
  activePortCallsPagination,
  allPortCallNotifications,
  arePushNotificationsEnabled,
  browserNotificationPermissionStatus,
  currentActivePortCallsPage,
  isActivePortCallsLoading,
  isApplyingNotificationsFilter,
  isRecentlyCompletedSectionLoading,
  isRequestingActivePortCalls,
  markAllNotificationsSeen,
  markNotificationsSeenForPortCall,
  notificationsFilter,
  recentlyCompleted,
  setActivePortCallPage,
  setActivePortCallsFilter,
  setBrowserNotificationPermissionStatus,
  setIsNotificationsBarOpen,
  setNotificationsFilter,
  setPushNotificationsEnabled,
  sortOverviewShips,
  sortOverviewShipsBy,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setIsNotificationsBarOpen(false)
  }, [setIsNotificationsBarOpen])

  const sortingOptions = [
    {
      label: t('overview.scheduledDeparture'),
      value: SCHEDULED_DEPARTURE,
    },
  ]

  const filterOptions = [
    {
      label: t('overview.allShips'),
      value: SHOW_ALL,
    },
    {
      label: t('overview.pinnedShips'),
      value: SHOW_PINNED_SHIPS,
    },
    {
      label: t('overview.pinnedPorts'),
      value: SHOW_PINNED_PORTS,
    },
  ]

  const goToPortCallPage = ({ imoNumber, portCallId }) => {
    const path = `${paths.ship.root}/${imoNumber}/${paths.ship.portCall}/${portCallId}/${paths.portCall.summary}`

    navigate(path)
  }

  const handlePaginationPageChange = page => setActivePortCallPage(page)

  const pageHeaderText = `${t('overview.activePortCalls')} ${
    activePortCallsFilterBy === SHOW_PINNED_PORTS
      ? `- ${t('overview.pinnedPorts')}`
      : activePortCallsFilterBy === SHOW_PINNED_SHIPS
      ? `- ${t('overview.pinnedShips')}`
      : ''
  }`

  const onSortBySelected = sortByValueInArray => {
    sortOverviewShips(first(sortByValueInArray))
  }

  const onFilterBySelected = filterByValueInArray => {
    setActivePortCallsFilter(first(filterByValueInArray))
  }

  return (
    <div className="overview__container">
      <div className="overview__port-calls-content">
        <div className="overview__heading-container">
          <Heading
            className="overview__heading"
            hasNoMarginBottom
            isDark
            level={4}
          >
            {pageHeaderText}
          </Heading>
          <Text isLabel>{t('overview.orderedByScheduledDeparture')}</Text>
        </div>
        <div className="overview__dropdowns-container">
          <div className="overview__dropdown-sorting-container">
            <Text>{t('overview.orderBy')}</Text>
            <Cascader
              allowClear={false}
              className="overview__dropdown-sorting"
              defaultValue={[sortOverviewShipsBy]}
              disabled={isActivePortCallsLoading}
              onChange={onSortBySelected}
              options={sortingOptions}
            />
          </div>

          <div>
            <Text>{t('overview.show')}</Text>
            <Cascader
              allowClear={false}
              className="overview__dropdown-filtering"
              disabled={isActivePortCallsLoading}
              onChange={onFilterBySelected}
              options={filterOptions}
              value={[activePortCallsFilterBy]}
            />
          </div>
        </div>

        <div className="overview__cards-container">
          {isActivePortCallsLoading || isRequestingActivePortCalls ? (
            <div className="overview__spin-container">
              <Spin size="large" />
            </div>
          ) : (
            <>
              <List
                dataSource={activePortCalls}
                grid={{
                  gutter: 16,
                  lg: 2,
                  md: 2,
                  sm: 1,
                  xl: 2,
                  xs: 1,
                  xxl: 3,
                }}
                renderItem={activePortCall => (
                  <List.Item>
                    <OverviewCard
                      portCall={activePortCall}
                      goToPortCallPage={() =>
                        goToPortCallPage({
                          imoNumber: activePortCall.imoNumber,
                          portCallId: activePortCall.portCallId,
                        })
                      }
                    />
                  </List.Item>
                )}
              />
              <Pagination
                current={currentActivePortCallsPage}
                hideOnSinglePage
                onChange={handlePaginationPageChange}
                pageSize={activePortCallsPagination.itemsPerPage}
                showSizeChanger={false}
                total={activePortCallsPagination.totalNumberOfItems}
              />
            </>
          )}
        </div>

        <Heading className="recently-completed__heading" isDark level={4}>
          {t('overview.recentlyCompleted')}
        </Heading>
        <div>
          <RecentlyCompletedSection
            goToPortCallPage={goToPortCallPage}
            isLoading={isRecentlyCompletedSectionLoading}
            recentlyCompleted={recentlyCompleted}
          />
        </div>
      </div>

      <Notifications
        allPortCallNotifications={allPortCallNotifications}
        arePushNotificationsEnabled={arePushNotificationsEnabled}
        isApplyingNotificationsFilter={isApplyingNotificationsFilter}
        markAllNotificationsSeen={markAllNotificationsSeen}
        markNotificationsSeenForPortCall={markNotificationsSeenForPortCall}
        notificationsFilter={notificationsFilter}
        setNotificationsFilter={setNotificationsFilter}
        setPushNotificationsEnabled={setPushNotificationsEnabled}
        browserNotificationPermissionStatus={
          browserNotificationPermissionStatus
        }
        setBrowserNotificationPermissionStatus={
          setBrowserNotificationPermissionStatus
        }
      />
    </div>
  )
}

Overview.propTypes = {
  activePortCalls: PropTypes.arrayOf(propTypes.activePortCall).isRequired,
  activePortCallsFilterBy: propTypes.activePortCallsFilterBy.isRequired,
  activePortCallsPagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    totalNumberOfItems: PropTypes.number.isRequired,
  }).isRequired,
  allPortCallNotifications: PropTypes.arrayOf(propTypes.portCallNotifications)
    .isRequired,
  arePushNotificationsEnabled: PropTypes.bool.isRequired,
  browserNotificationPermissionStatus:
    propTypes.browserNotificationPermissionStatus.isRequired,
  currentActivePortCallsPage: PropTypes.number.isRequired,
  isActivePortCallsLoading: PropTypes.bool.isRequired,
  isApplyingNotificationsFilter: PropTypes.bool.isRequired,
  isRecentlyCompletedSectionLoading: PropTypes.bool.isRequired,
  isRequestingActivePortCalls: PropTypes.bool.isRequired,
  markAllNotificationsSeen: PropTypes.func.isRequired,
  markNotificationsSeenForPortCall: PropTypes.func.isRequired,
  notificationsFilter: propTypes.notificationsFilter.isRequired,
  recentlyCompleted: propTypes.recentlyCompletedPortCalls.isRequired,
  setActivePortCallPage: PropTypes.func.isRequired,
  setActivePortCallsFilter: PropTypes.func.isRequired,
  setBrowserNotificationPermissionStatus: PropTypes.func.isRequired,
  setIsNotificationsBarOpen: PropTypes.func.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
  setPushNotificationsEnabled: PropTypes.func.isRequired,
  sortOverviewShips: PropTypes.func.isRequired,
  sortOverviewShipsBy: PropTypes.string.isRequired,
}

export default Overview
