import { BackTop, Button } from 'antd'
import { HomeOutlined, LeftOutlined } from '@ant-design/icons'
import Card from 'components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'
import withLayout from 'components/layout'

import './userManual.less'
import {
  ActivePortCall,
  AddingGantries,
  BapliesAndRestows,
  BestPractices,
  CancellingContainers,
  CargoEvents,
  CargoMateDashboardDocs,
  CargoMateDevice,
  CargoMateHomeScreen,
  CargoMateMobileDocs,
  CargoPlan,
  ContainerInfo,
  ContainerSearch,
  DismissingAGantry,
  EndPortCall,
  Feedback,
  HistoricPortCalls,
  Home,
  LearnMore,
  LogbookEvents,
  LoggingContainerMoves,
  LogLashingAndTwistlocks,
  MobileDashboard,
  MovingAGantryToAnotherBay,
  OperationalConsiderations,
  PortCallNotClosed,
  ProgressScreen,
  StartEditPortCall,
  TempStopAllGantries,
  TempStopSingleGantry,
  UpdatingScheduledDeparture,
  VesselArrivalInfo,
  VesselDepartureInfo,
  WebDashboardOverview,
} from './Pages'

const UserManual = ({
  areNavigationButtonsDisabled,
  currentPage,
  goBack,
  goHome,
  setPage,
}) => {
  const pageComponents = {
    [userManualPages.home]: <Home setPage={setPage} />,
    [userManualPages.cargoMateMobileDocs]: (
      <CargoMateMobileDocs setPage={setPage} />
    ),
    [userManualPages.cargoMateDevice]: <CargoMateDevice />,
    [userManualPages.bestPractices]: <BestPractices />,
    [userManualPages.cargoMateHomeScreen]: <CargoMateHomeScreen />,
    [userManualPages.startEditPortCall]: <StartEditPortCall />,
    [userManualPages.progressScreen]: <ProgressScreen setPage={setPage} />,
    [userManualPages.bapliesAndRestows]: <BapliesAndRestows />,
    [userManualPages.vesselArrivalInfo]: <VesselArrivalInfo />,
    [userManualPages.cargoEvents]: <CargoEvents />,
    [userManualPages.logbookEvents]: <LogbookEvents />,
    [userManualPages.vesselDepartureInfo]: <VesselDepartureInfo />,
    [userManualPages.endPortCall]: <EndPortCall />,
    [userManualPages.cargoPlan]: <CargoPlan setPage={setPage} />,
    [userManualPages.addingGantries]: <AddingGantries />,
    [userManualPages.movingAGantryToAnotherBay]: <MovingAGantryToAnotherBay />,
    [userManualPages.dismissingAGantry]: <DismissingAGantry />,
    [userManualPages.tempStopSingleGantry]: <TempStopSingleGantry />,
    [userManualPages.tempStopAllGantries]: <TempStopAllGantries />,
    [userManualPages.loggingContainerMoves]: <LoggingContainerMoves />,
    [userManualPages.cancellingContainers]: <CancellingContainers />,
    [userManualPages.logLashingAndTwistlocks]: <LogLashingAndTwistlocks />,
    [userManualPages.containerSearch]: <ContainerSearch />,
    [userManualPages.containerInfo]: <ContainerInfo />,
    [userManualPages.mobileDashboard]: <MobileDashboard setPage={setPage} />,
    [userManualPages.updatingScheduledDeparture]: (
      <UpdatingScheduledDeparture />
    ),
    [userManualPages.feedback]: <Feedback />,
    [userManualPages.learnMore]: <LearnMore />,
    [userManualPages.cargoMateDashboardDocs]: (
      <CargoMateDashboardDocs setPage={setPage} />
    ),
    [userManualPages.webDashboardOverview]: <WebDashboardOverview />,
    [userManualPages.activePortCall]: <ActivePortCall />,
    [userManualPages.historicPortCalls]: <HistoricPortCalls />,
    [userManualPages.portCallNotClosed]: <PortCallNotClosed />,
    [userManualPages.operationalConsiderations]: <OperationalConsiderations />,
  }

  return (
    <Card className="user-manual__container">
      <div className="user-manual__navigation-buttons--container">
        <Button
          className="user-manual__navigation-button"
          disabled={areNavigationButtonsDisabled}
          onClick={goHome}
          shape="circle"
          type="primary"
        >
          <HomeOutlined />
        </Button>

        <Button
          className="user-manual__navigation-button"
          disabled={areNavigationButtonsDisabled}
          onClick={goBack}
          shape="circle"
        >
          <LeftOutlined />
        </Button>
      </div>

      <div className="user-manual__content-container">
        {pageComponents[currentPage]}
      </div>

      <BackTop />
    </Card>
  )
}

UserManual.propTypes = {
  areNavigationButtonsDisabled: PropTypes.bool.isRequired,
  currentPage: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
  goHome: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
}

export default withLayout(UserManual)
