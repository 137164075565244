import Heading from 'components/Heading'
import paths from 'constants/paths'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import userManualPages from 'constants/userManualPages'

import cargoOperationsTrend from './Images/cargo-operations-trend.png'
import containerMovesPerHour from './Images/container-moves-per-hour.png'
import gantryProductivity from './Images/gantry-productivity.png'
import gantryTimeline from './Images/gantry-timeline.png'
import gantryTimelineHover from './Images/gantry-timeline-hover.png'
import historicalData from './Images/historical-data.png'
import hoverGantryIcon from './Images/hover-gantry-icon.png'
import localTimeHover from './Images/local-time-hover.png'
import longCraneIcon from './Images/long-crane-icon.png'
import milestones from './Images/milestones.png'
import overallProductivity from './Images/overall-productivity.png'
import summaryTab from './Images/summary-tab.png'
import tempStopGantry from './Images/temp-stop-gantry-dashboard.png'
import totalContainers from './Images/total-containers.png'

const ActivePortCall = () => {
  const navigate = useNavigate()

  const navigateToOperationalConsiderations = () => {
    navigate(`${paths.manual}/${userManualPages.operationalConsiderations}`)
    window.scrollTo(0, 0)
  }

  return (
    <div>
      <Heading level={2}>Active Port Call</Heading>

      <Heading hasNoMarginBottom isDark level={4}>
        Sections
      </Heading>
      <ul>
        <li>
          <a href="#cargo-operations">Cargo Operations</a>
        </li>
        <li>
          <a href="#local-or-utc-time">Local or UTC time?</a>
        </li>
        <li>
          <a href="#milestones">Milestones</a>
        </li>
        <li>
          <a href="#container-vs-gantry-moves">
            Container moves vs Gantry moves
          </a>
        </li>
        <li>
          <a href="#ship-overview">Ship Overview</a>
        </li>
        <li>
          <a href="#productivity">Productivity</a>
        </li>
        <li>
          <a href="#comments-and-logbook">Comments and Logbook</a>
        </li>
      </ul>

      <Heading id="cargo-operations" isDark level={3}>
        Cargo Operations
      </Heading>
      <p>
        Any port call on the Dashboard Overview can be clicked to view more
        detailed information.
      </p>
      <p>
        The first screen you are taken to is the Cargo Operations view. Details
        about the Estimated completion time, Port call milestones, Overall
        remaining moves and detailed remaining moves can all be viewed.
      </p>
      <p>
        The remaining moves and port call milestones are completed on the
        CargoMate device on the vessel. Each time the vessel makes a record on
        CargoMate, this updates the Dashboard.
      </p>

      <Heading id="local-or-utc-time" isDark level={3}>
        Local or UTC time?
      </Heading>
      <p>
        Hovering over time event data will show whether the time is Local Time
        or UTC. In general, CargoMate will also opt to show Local Time for the
        Dashboard user where possible, and will only default to UTC if lacking
        data.
      </p>
      <img src={localTimeHover} />

      <Heading id="milestones" isDark level={3}>
        Milestones
      </Heading>
      <p>
        All milestones are independent of moves data. 100% completion of loading
        does not mean that loading is complete, as there may be contextual
        factors delaying this from being reported. This can include completion
        of lashing, checking of twistlocks and awaiting cargo manifests or final
        loading plans. This is why CargoMate does not automatically populate
        milestones once the remaining moves is zero.
      </p>
      <img src={milestones} />
      <p>
        Milestones may not always be entered in order, but all milestones will
        be entered before a port call is complete (loading/discharging complete
        are only entered if moves for either occur in a port call).
      </p>
      <img src={summaryTab} />

      <Heading id="container-vs-gantry-moves" isDark level={3}>
        Container moves vs Gantry moves
      </Heading>
      <p>An important distinction is shown on the Summary view.</p>
      <p>
        Container moves are the number of containers left to be worked. This is
        an indicator of the remaining cargo to be discharged or loaded. The rate
        at which the containers are being worked is shown on the container moves
        per hour indicator.
      </p>
      <img src={totalContainers} />
      <img src={containerMovesPerHour} />

      <p>
        Total containers and container moves per hour are best used for
        comparison with:
      </p>
      <ul>
        <li>
          The baplies uploaded to the CargoMate device to ensure the correct
          amount of cargo for the port call is shown
        </li>
        <li>
          Comparing indicative productivity figures from the container terminal
        </li>
      </ul>

      <p>
        Below the Container moves progress panel, the Gantry moves panel is
        shown.
      </p>
      <p>
        This is the number of discharge, load and restow moves expected to be
        carried out by the gantries. Critically, these moves are optimised for
        twin-lift gantries. More details can be found at this link:
        <a onClick={navigateToOperationalConsiderations}>
          {' '}
          Operational considerations
        </a>
      </p>

      <p>Gantry moves are best used for:</p>
      <ul>
        <li>
          Use onboard ship when notifying remaining moves before cargo
          operations completion
        </li>
        <li>Discussing remaining moves when referring to a cargo delay</li>
      </ul>

      <Heading id="ship-overview" isDark level={3}>
        Ship Overview
      </Heading>
      <p>
        In addition, the Ship Overview shows active gantries working on the
        vessel, the progress of lashing and twistlock checks, the status of open
        and closed hatch covers, and the progress in loading and discharging
        high-value cargo such as reefer, IMDG, OOG and Breakbulk.
      </p>
      <p>Hovering over a gantry icon shows its status.</p>
      <img src={hoverGantryIcon} />
      <p>
        A gantry can work on the deck and hold simultaneously. A gantry icon
        enclosed within a circle shows the calculated long crane. This is the
        gantry expected to finish last based on the latest estimation completion
        time and remaining moves. The long crane can change throughout cargo
        operations, especially in cargo operations where the crane/cargo split
        is evenly distributed.
      </p>
      <img src={longCraneIcon} />
      <p>
        The coffee cup shows that a gantry has temporarily stopped. This likely
        due to a planned break, but may also be indicative of an unplanned
        downtime event. CargoMate categorises temporary stops as idle time.
      </p>
      <img src={tempStopGantry} />

      <Heading id="productivity" isDark level={3}>
        Productivity
      </Heading>
      <p>The second screen available is the Productivity screen.</p>
      <p>This screen shows three charts related to Gantry productivity.</p>
      <p>
        The Gantry Productivity shows active and inactive gantries, with both
        the number of moves per gantry and the average gantry moves per hour.
        Hovering over a gantry will give you additional information.
      </p>
      <img src={gantryProductivity} />
      <p>
        Alongside this chart, the overall productivity is also shown. This
        includes the average moves per hour across all gantries, the total
        number of completed moves and, if available, details of the expected
        longest crane (the gantry expected to finish last) and previous
        historical data for the long crane (if any) to support.
      </p>
      <img src={overallProductivity} />
      <p>
        The average moves per hour is the average moves across{' '}
        <strong>all gantries</strong> since cargo operations commence. This will
        include dismissed gantries and gantries that may be temporarily stopped.
        Individual average moves per hour for gantries can be seen by hovering
        over the respective gantry in the chart.
      </p>
      <p>
        The historical data for the long crane shows the average moves per hour
        for the specific gantry and how many port calls the data for this gantry
        has been collected. When the port call is closed, the long crane will be
        the last crane calculated to finish last, not the actual long crane
        which may differ.
      </p>
      <img src={historicalData} />
      <p>
        As the long crane calculations use the Gantry ID for comparing previous
        performance, guidance is provided to the vessels to imply the importance
        of correctly identified gantries.
      </p>
      <p>
        Long crane calculations are based on the current number of active
        gantries and remaining moves, taking into account vessel parameters such
        as the position of the accommodation and engine superstructure.
      </p>
      <p>
        The Gantry timeline chart shows when gantries are working or not. Each
        gantry has it’s own timeline, and coloured sectors show the gantry
        status over time
      </p>
      <img src={gantryTimeline} />
      <ul>
        <li>Green sectors - Active gantry carrying out container moves</li>
        <li>
          Orange sectors - Inactive or temporary stopped gantry (due to breaks,
          shift changes or gantry downtime)
        </li>
        <li>
          Grey bar - The gantry is ‘at bay’ - An ‘at bay’ gantry has been
          assigned to a bay but is not currently discharging or loading
          containers.
        </li>
        <li>
          White circle with red border - Gantry has been dismissed, has moved
          away from the vessel or is no longer in operation (the gantry
          powers-down or booms-up).
        </li>
      </ul>
      <p>
        The Gantry timeline shows the historical status of the gantry, whereas
        the Ship Overview shows the current location and status of the gantries.
      </p>
      <p>
        To view more information about the status of the gantry, you can hover
        over any area of the chart.
      </p>
      <img src={gantryTimelineHover} />
      <p>
        The final chart available is the Cargo operations trend. As one of the
        most powerful data visualisations in CargoMate, this chart can show
        whether a vessel is expected to complete ahead, on or later than
        scheduled.
      </p>
      <p>
        Further details on how the trend is calculated can be found in the
        Operational Considerations guidance.
      </p>
      <img src={cargoOperationsTrend} />
      <p>The chart consists of three data sets:</p>
      <ul>
        <li>Dark blue lines and points - Scheduled departure time</li>
        <ul>
          <li>These are entered by the vessel on the CargoMate device</li>
        </ul>
        <li>
          Orange lines and points - Estimated time of cargo operations complete
        </li>
        <li>Green line - Actual time of cargo operations complete</li>
        <li>Red dashed vertical lines - Discharge moves/baplie updated</li>
        <li>Light blue dashed vertical lines - Load moves/baplie updated</li>
        <li>Purple dashed vertical lines - Restow moves updated</li>
      </ul>
      <p>
        In active port calls, the orange line of estimated completion time
        should be compared to the blue line of scheduled departure.
      </p>
      <p>
        If the estimated completion time is trending below the scheduled
        departure time, then the vessel should complete cargo operations ahead
        of schedule.
      </p>
      <p>
        If the estimated completion time is trending above the scheduled
        departure time, then the vessel should complete cargo operations later
        than scheduled.
      </p>
      <p>
        Changes to the number of moves by updating either the discharge, load or
        restow moves can have a direct impact on estimations. Estimations with
        filled orange circles are based on the latest updated moves file, and
        can be considered more reliable indicators than the earlier unfilled
        orange circles.
      </p>

      <Heading id="comments-and-logbook" isDark level={3}>
        Comments and Logbook
      </Heading>
      <p>
        CargoMate is well-suited for telling users when and where events are
        happening (for example, temporary stoppages). What CargoMate cannot do
        without the assistance of its user’s is provide the context and reasons
        why events happen. The Comments and Logbook section of an Active Port
        Call is the area for users to do just that.
      </p>
      <p>
        Notifications can be enabled and disabled for Comments and Logbook
        Remarks.
      </p>

      <Heading isDark level={4}>
        Comments
      </Heading>
      <p>
        Comments should be considered as radio broadcasts or statements. They
        can serve as a record for why an event has happened, or events that may
        happen, and they may or may not be read by other users.
      </p>
      <p>The following are examples of such comments:</p>
      <ul>
        <li>Reasons for delay (e.g. lack of labour, shortage of trucks)</li>
        <li>
          Reasons for changes to expected departure time (e.g. heavy weather
          expected)
        </li>
      </ul>
      <p>
        Comments are made at the discretion of the user. If you have information
        that is useful to other CargoMate users observing your port call, you
        should add a Comment for it.
      </p>

      <Heading isDark level={4}>
        Logbook
      </Heading>
      <p>
        Logbook remarks are entered by the crew on the CargoMate device. As the
        remarks are designed to cover replacements to the paper logbook, there
        may be references to safety rounds, gangway and vessel safe access
        monitoring in addition to cargo events such as damage, reefer
        malfunctions and plugging-in/out times of reefer containers.
      </p>
    </div>
  )
}

export default ActivePortCall
