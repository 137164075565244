import { Alert, List, Spin } from 'antd'
import PinButton, { pinButtonTypes } from 'components/PinButton'
import React, { useState } from 'react'
import Card from 'components/Card'
import { DEFAULT_PAGINATION_PAGE } from 'global/settings'
import Heading from 'components/Heading'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import sortBy from 'lodash/sortBy'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import withLayout from 'components/layout'

import './ports.less'
import MoreInfoContent from './components/MoreInfoContent'
import NoPinnedPorts from './components/NoPinnedPorts'
import PinnedPort from './components/PinnedPort'
import PortTable from './components/PortTable'

// Minimum number of pinned ports a user needs to have before
// showing the 'Open all pinned ports' link.
const MIN_PORTS_TO_SHOW_OPEN_ALL_LINK = 2

// Local storage key for when a user has closed the help component
// shown when no ports have been pinned and no port has been opened.
const PORTS_HELP_CLOSED_KEY = 'PORTS_HELP_CLOSED_KEY'

export const FILTER_KEYS = {
  CARGO_OPS_COMMENCED: 'cargoOpsCommenced',
  CARGO_OPS_COMPLETE: 'cargoOpsComplete',
  IMO_NUMBER: 'imoNumber',
  VESSEL_NAME: 'vesselName',
  VOYAGE_NUMBER: 'voyageNumber',
}

const Ports = ({
  closePort,
  currentPageByPort,
  goToPaginationPage,
  goToPortCall,
  isRequestingPinnedPorts,
  openAllPinnedPorts,
  openPinnedPort,
  pinnedPorts,
  ports,
  requestingPorts,
  showPort,
}) => {
  const { t } = useTranslation()

  const initialFilterParams = {
    [FILTER_KEYS.CARGO_OPS_COMMENCED]: '',
    [FILTER_KEYS.CARGO_OPS_COMPLETE]: '',
    [FILTER_KEYS.IMO_NUMBER]: '',
    [FILTER_KEYS.VESSEL_NAME]: '',
    [FILTER_KEYS.VOYAGE_NUMBER]: '',
  }

  const [filterParamsByPort, setFilterParamsByPort] = useState()

  const renderPinnedPort = port => (
    <PinnedPort
      openPinnedPort={openPinnedPort}
      portLocodes={port.portLocodes}
      portName={port.portName}
    />
  )

  const closeHelp = () => {
    localStorage.setItem(PORTS_HELP_CLOSED_KEY, true)
  }

  const hasHelpBeenClosed = JSON.parse(
    localStorage.getItem(PORTS_HELP_CLOSED_KEY) || false
  )

  return (
    <div className="page__container">
      {isEmpty(showPort) && isEmpty(pinnedPorts) && !hasHelpBeenClosed && (
        <Alert
          className="ports__help"
          closable
          description={t('ports.useTheSearchBox')}
          message={t('ports.gettingStarted')}
          onClose={closeHelp}
          showIcon
          type="info"
        />
      )}

      <Card
        className="ports__pinned-ports"
        isCollapsible
        key={uuidV4()}
        moreInfoContent={isEmpty(pinnedPorts) ? null : <MoreInfoContent />}
        moreInfoTitle={t('ports.moreInfo.pinnedPorts')}
        title={t('ports.yourPinnedPorts')}
      >
        {isRequestingPinnedPorts ? (
          <Spin className="ports__pinned-ports-list--spin" size="large" />
        ) : isEmpty(pinnedPorts) ? (
          <NoPinnedPorts />
        ) : (
          <List
            className="ports__pinned-ports-list"
            dataSource={[...pinnedPorts].sort()}
            renderItem={renderPinnedPort}
          />
        )}

        {!isRequestingPinnedPorts &&
          pinnedPorts.length >= MIN_PORTS_TO_SHOW_OPEN_ALL_LINK && (
            <a
              className="ports__open-all-ports"
              href="/"
              onClick={event => {
                event.preventDefault()
                openAllPinnedPorts()
              }}
            >
              {t('ports.openAllPinnedPorts')}
            </a>
          )}
      </Card>

      {map(sortBy(showPort, 'portName'), port => {
        if (!port) {
          // An unknown port name was sent in as a query parameter.
          return null
        }

        const { portName, portLocodes } = port

        const filterParams =
          filterParamsByPort && filterParamsByPort[portName]
            ? filterParamsByPort[portName]
            : initialFilterParams

        const handleSetFilterParams = ({
          cargoOpsCommenced = '',
          cargoOpsComplete = '',
          imoNumber = '',
          vesselName = '',
          voyageNumber = '',
        }) => {
          const newFilterParams = {
            cargoOpsCommenced,
            cargoOpsComplete,
            imoNumber,
            vesselName,
            voyageNumber,
          }
          setFilterParamsByPort(prevState => ({
            ...prevState,
            [portName]: newFilterParams,
          }))
          goToPaginationPage(1, portName, portLocodes, newFilterParams)
        }

        return (
          <Card
            className="ports__port-card"
            isClosable
            isCollapsible
            key={uuidV4()}
            onClose={() => closePort(portName, portLocodes)}
            title={
              <div className={'ports__port-heading-container'}>
                <Heading hasNoMarginBottom isDark level={4}>
                  {`${t('ports.portCallsIn')} ${portName} (${join(
                    portLocodes,
                    ', '
                  )})`}
                </Heading>

                <PinButton
                  containerClassName="ports__pin-port-button"
                  item={port}
                  type={pinButtonTypes.PORT}
                />
              </div>
            }
          >
            <PortTable
              filterParams={filterParams}
              goToPaginationPage={goToPaginationPage}
              goToPortCall={goToPortCall}
              isLoading={requestingPorts.includes(portName)}
              onFilterResult={handleSetFilterParams}
              pagination={ports[portName] ? ports[portName].pagination : {}}
              portCalls={ports[portName] ? ports[portName].portCalls : []}
              portLocodes={portLocodes}
              portName={portName}
              currentPage={
                currentPageByPort[portName] || DEFAULT_PAGINATION_PAGE
              }
            />
          </Card>
        )
      })}
    </div>
  )
}

Ports.propTypes = {
  closePort: PropTypes.func.isRequired,
  currentPageByPort: propTypes.portPagination.isRequired,
  goToPaginationPage: PropTypes.func.isRequired,
  goToPortCall: PropTypes.func.isRequired,
  isRequestingPinnedPorts: PropTypes.bool.isRequired,
  openAllPinnedPorts: PropTypes.func.isRequired,
  openPinnedPort: PropTypes.func.isRequired,
  pinnedPorts: PropTypes.arrayOf(propTypes.pinnedPort),
  ports: PropTypes.objectOf(propTypes.port).isRequired,
  requestingPorts: PropTypes.arrayOf(PropTypes.string).isRequired,
  showPort: PropTypes.arrayOf(
    PropTypes.shape({
      portLocodes: PropTypes.array.isRequired,
      portName: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default withLayout(Ports)
