export default {
  // Redux migration for v3.0.45:
  // Changed overview page's port calls endpoint structure
  0: state => ({
    ...state,
    overview: {
      ...state.overview,
      activePortCalls: [],
      portCalls: null,
      recentlyCompletedPortCalls: [],
      requestingActivePortCalls: false,
      requestingRecentlyCompletedPortCalls: false,
    },
  }),
  // Redux migration for v3.0.58:
  // Added draft survey to port call page
  1: state => ({
    ...state,
    portCall: {
      ...state.portCall,
      draftSurvey: {
        arrivalDrafts: null,
        arrivalWaterDensity: null,
        departureDrafts: null,
        departureWaterDensity: null,
      },
      requestingDraftSurvey: false,
    },
  }),
  // Redux migration for overivew pagination
  2: state => ({
    ...state,
    overview: {
      ...state.overview,
      activePortCalls: {
        pagination: {
          itemsPerPage: 0,
          totalNumberOfItems: 0,
        },
        portCalls: state.overview.activePortCalls,
        requestingNextPage: false,
      },
      filterBy: 'SHOW_ALL',
      sortBy: 'SCHEDULED_DEPARTURE',
    },
  }),
  // Redux migration for v3.0.73:
  // No longer store pinned ships and ports locally
  3: state => ({
    ...state,
    ports: {
      ...state.ports,
      pinnedPorts: [],
      requestingPinnedPorts: false,
    },
    ship: {
      ...state.ship,
      pinnedShips: [],
      requestingPinnedShips: false,
    },
  }),
  // Redux migration for v4.0.1-beta.1
  // Adding notifications and redesigning port call page
  4: state => ({
    ...state,
    notifications: {
      allPortCallNotifications: [],
      arePushNotificationsEnabled: true,
      browserNotificationPermissionStatus: '',
      filterBy: 'PINNED_SHIPS',
      isApplyingNotificationsFilter: false,
      isNotificationsBarOpen: false,
      lastUpdated: null,
    },
    portCall: {
      ...state.portCall,
      commentDraft: null,
      containerMovesProgress: null,
      departureAndCompletionTime: {
        cargoOpsComplete: {
          actual: null,
          estimated: null,
        },
        scheduledDeparture: null,
      },
      gantryMovesSummary: {
        discharge: null,
        load: null,
        restows: null,
      },
      gearboxLocations: [],
      logbookRemarks: [],
      milestones: {
        cargoOperationsCommence: {
          loggedAt: null,
        },
        cargoOperationsComplete: {
          loggedAt: null,
        },
        dischargeComplete: {
          loggedAt: null,
        },
        dischargeStarted: {
          loggedAt: null,
          status: null,
        },
        gangwayRiggedAndChecked: {
          alongside: null,
          loggedAt: null,
        },
        gearboxes: {
          numberOfGearboxes: null,
          numberOnboard: null,
        },
        loadComplete: {
          loggedAt: null,
        },
        loadStarted: {
          loggedAt: null,
          status: null,
        },
        vesselReadyForOperation: {
          loggedAt: null,
        },
      },
      reportsSummary: null,
      requestingContainerMovesProgress: false,
      requestingDepartureAndCompletionTime: false,
      requestingGantryMovesSummary: false,
      requestingGearboxLocations: false,
      requestingLogbookRemarks: false,
      requestingMilestones: false,
      requestingReportsSummary: false,
      requestingShipOverview: false,
      shipOverview: null,
    },
  }),
  // Redux migration for v4.0.1-beta.5
  // Adds fleet status page
  5: state => ({
    ...state,
    fleet: {
      ...state.fleet,
      fleetStatus: [],
      requestingFleetStatus: false,
    },
    user: {
      ...state.user,
      cognitoGroup: null,
      isUserSSO: false,
    },
  }),
}
