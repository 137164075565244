import { Alert } from 'antd'
import Heading from 'components/Heading'
import React from 'react'

import addedGantry from './Images/added-gantry.png'
import addGantry from './Images/add-gantry.png'
import addNewGantry from './Images/add-new-gantry.png'
import addViewGantries from './Images/add-view-gantries.png'
import atBayGantry from './Images/at-bay-gantry.png'
import bayplan from './Images/bayplan.png'
import bayplanNoGantry from './Images/bayplan-no-gantry.png'
import cargoPlanGantryAdded from './Images/cargo-plan-gantry-added.png'
import selectGantry from './Images/select-gantry.png'
import viewGantriesNoWork from './Images/view-gantries-no-work.png'

const AddingGantries = () => {
  const GantryIdMessage = () => (
    <div>
      <p>
        Gantry ID numbers must match the <strong>actual gantry ID.</strong>
      </p>
      <p>
        Gantry ID’s <strong>cannot be changed/edited</strong> during a port
        call.
      </p>

      <p>
        This is for landside users to correctly identify the performance of a
        terminal’s gantry’s and for vessel users to receive correct historic
        data for long crane calculations.
      </p>
    </div>
  )

  return (
    <div>
      <Heading level={2}>Adding gantries</Heading>

      <p>
        The most important step to start logging work is to add a gantry to a
        bay.
      </p>
      <p>To do this, press on the bay you want to add a gantry to.</p>

      <img src={bayplanNoGantry} />
      <p>Press ‘Add gantry’ to open the ‘Add gantry’ screen.</p>

      <img src={addGantry} />
      <p>
        Press ‘Select gantry’. If no gantries have been added, then the
        following ‘Select gantry’ screen will show No gantries added.
      </p>
      <img src={selectGantry} />

      <p>Press ‘Add new gantry’.</p>
      <img src={addNewGantry} />

      <p>Enter the Gantry ID number for the new gantry.</p>

      <Alert description={<GantryIdMessage />} showIcon type="warning" />

      <p>
        Once the gantry has been created, the time it started at the bay can be
        added.
      </p>

      <img src={addedGantry} />

      <p>
        The gantry has now been created, and the bay will show the gantry name,
        current gantry status of ‘At bay’, and two buttons; one to log gantry
        temporary stops and another to dismiss the gantry when it stops working
        on the vessel.
      </p>
      <img src={bayplan} />

      <p>
        Go back to return to the Cargo plan. The bay now has a green border
        around it, showing an active gantry is ready for work to be recorded.
      </p>
      <img src={atBayGantry} />

      <p>
        Two new buttons appear on the left of the bay. The top button shows that
        twistlocks need to be checked after loading. The bottom button shows
        lashing needs to be checked after loading.
      </p>

      <Alert
        description="Gantry names/ID cannot be changed or edited. Please ensure the gantry name/ID is correct before submission."
        showIcon
        type="warning"
      />

      <p>
        You can also add gantries, in preparation for work, without adding it to
        a bay. At the top of the screen, press on the button with the gantry
      </p>
      <img src={cargoPlanGantryAdded} />

      <p>
        The ‘Add/View gantries’ screen shows an overview of all added gantries.
        Press on ‘Add gantry’ to add a gantry without adding it to a bay, and
        follow the onscreen steps.
      </p>
      <img src={addViewGantries} />

      <p>
        Once you have added a gantry, you have the option to delete it by
        pressing on the red delete button. This option is only available when
        the gantry has not been added to a bay.
      </p>
      <img src={viewGantriesNoWork} />
    </div>
  )
}

export default AddingGantries
