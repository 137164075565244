import { MailOutlined, TeamOutlined } from '@ant-design/icons'
import Heading from 'components/Heading'
import helpAndFeedback from 'constants/helpAndFeedback'
import { Link } from 'react-router-dom'
import paths from 'constants/paths'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './help.less'
import QuestionsAndAnswers from './Components/QuestionsAndAnswers'
import SupportOptionCard from './Components/SupportOptionCard'

const Help = () => {
  const { t } = useTranslation()

  const generalFAQs = [
    {
      answer: t('help.timesDisplayed.answer'),
      question: t('help.timesDisplayed.question'),
    },
  ]

  const howDoIUseCargoMateContent = {
    answer: (
      <Text>
        {t('help.howDoIUseCargoMate.answer1')}{' '}
        <Link to={paths.manual}>{t('help.howDoIUseCargoMate.answer2')}</Link>{' '}
        {t('help.howDoIUseCargoMate.answer3')}
      </Text>
    ),
    question: t('help.howDoIUseCargoMate.question'),
  }

  const enableNotificationPermissionContent = {
    answer: (
      <>
        <Text>
          {t(
            'help.enableNotificationsPermission.yourBrowserMustAllowPermission'
          )}
        </Text>
        <ul>
          <li>
            <a
              href="https://support.google.com/chrome/answer/3220216"
              rel="noreferrer"
              target="_blank"
            >
              {t('help.enableNotificationsPermission.instructionsForChrome')}
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/microsoft-edge/manage-website-notifications-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b"
              rel="noreferrer"
              target="_blank"
            >
              {t('help.enableNotificationsPermission.instructionsForEdge')}
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/push-notifications-firefox"
              rel="noreferrer"
              target="_blank"
            >
              {t('help.enableNotificationsPermission.instructionsForFirefox')}
            </a>
          </li>
        </ul>
        <Text>
          {t('help.enableNotificationsPermission.forOtherWebBrowsers')}
        </Text>
      </>
    ),
    question: t('help.enableNotificationsPermission.howDoIAllowPermission'),
  }

  const havePermissionNoNotificationsContent = {
    answer: (
      <>
        <Text>{t('help.havePermissions.checkSystemNotSilent')}</Text>
        <ul>
          <li>
            <a
              href="https://support.apple.com/en-gb/guide/mac-help/mh40583/14.0/mac/14.0"
              rel="noreferrer"
              target="_blank"
            >
              {t('help.havePermissions.instructionsForMac')}
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/windows/change-notification-settings-in-windows-8942c744-6198-fe56-4639-34320cf9444e#WindowsVersion=Windows_10"
              rel="noreferrer"
              target="_blank"
            >
              {t('help.havePermissions.instructionsForWindows')}
            </a>
          </li>
        </ul>
      </>
    ),
    question: t('help.havePermissions.stillNotGettingNotifications'),
  }

  return (
    <div className="page__container">
      <Heading level={1}>{t('help.helpAndSupport')}</Heading>

      <Heading isDark level={2}>
        {t('help.frequentlyAskedQuestions')}
      </Heading>

      <Heading isDark level={5}>
        {t('help.general')}
      </Heading>
      <QuestionsAndAnswers customContent={howDoIUseCargoMateContent} />
      <QuestionsAndAnswers faqs={generalFAQs} />

      <Heading isDark level={5}>
        {t('help.notifications')}
      </Heading>
      <QuestionsAndAnswers
        customContent={enableNotificationPermissionContent}
      />
      <QuestionsAndAnswers
        customContent={havePermissionNoNotificationsContent}
      />

      <Heading isDark level={2}>
        {t('help.stillNeedHelp')}
      </Heading>

      <div className="help__support-option-card-container">
        <SupportOptionCard
          ButtonIcon={MailOutlined}
          HeaderIcon={MailOutlined}
          buttonTitle={t('help.emailSupport')}
          redirectLink={helpAndFeedback.zendeskMailTo}
          title={t('help.contactUs')}
        >
          <>
            <Text>{t('help.emailUsYourQuestions')}</Text>
            <Text>{t('help.invitedToJoinHelpCentre')}</Text>
          </>
        </SupportOptionCard>

        <SupportOptionCard
          ButtonIcon={TeamOutlined}
          HeaderIcon={TeamOutlined}
          buttonTitle={t('help.visitHelpCentre')}
          redirectLink={helpAndFeedback.helpCentreURL}
          title={t('help.helpCentre')}
        >
          <>
            <Text>{t('help.visitHelpCentreToCreateTicket')}</Text>
            <Text>{t('help.otherwiseEmail')}</Text>
          </>
        </SupportOptionCard>
      </div>
    </div>
  )
}

export default Help
