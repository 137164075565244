import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = ({ wasPortCallInterrupted }) => {
  const { t } = useTranslation()

  return (
    <div className="milestones__more-info-content-container">
      {wasPortCallInterrupted && (
        <div>
          <Heading hasNoMarginBottom isDark level={3}>
            {t('portCall.milestones.moreInfo.missingInfo')}
          </Heading>

          <Text>{t('portCall.milestones.moreInfo.somethingWentWrong')}</Text>
        </div>
      )}

      <Heading hasNoMarginBottom isDark level={3}>
        {t('portCall.milestones.moreInfo.displayedDates')}
      </Heading>

      <Text>{t('portCall.milestones.moreInfo.keyEventsReportedByCrew')}</Text>

      <Text>{t('portCall.milestones.moreInfo.dischargeAndLoadTimes')}</Text>

      <Text>{t('portCall.milestones.moreInfo.allDatesLocalTime')}</Text>

      <Text>{t('portCall.milestones.moreInfo.ifNotRecognised')}</Text>
    </div>
  )
}

MoreInfoContent.propTypes = {
  wasPortCallInterrupted: PropTypes.bool.isRequired,
}

export default MoreInfoContent
