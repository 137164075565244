import Icon, { FolderFilled, HomeFilled } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AnchorIcon } from 'assets/icons/anchor.svg'
import cargomateLogo from 'assets/cargomate-logo.svg'
import { connect } from 'react-redux'
import { includes } from 'lodash'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { selectors } from 'domain'
import userCognitoGroups from 'constants/userCognitoGroups'
import userRoles from 'constants/userRoles'
import { useTranslation } from 'react-i18next'

import './navbar.less'
import Footer from './components/Footer'

const { Sider } = Layout

const Navbar = ({
  cognitoGroup,
  selectedNavbarItem,
  userImoNumber,
  userRole,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const onMenuItemSelected = item => {
    navigate(item.key)
  }

  const goToHomePage = () =>
    userRole === userRoles.SHIP
      ? navigate(`${paths.ship.root}/${userImoNumber}`)
      : navigate(paths.overview)

  const isUserHL = cognitoGroup
    ? includes(cognitoGroup, userCognitoGroups.HAPAG) ||
      includes(cognitoGroup, userCognitoGroups.HL)
    : false
  const shouldShowFleetStatus = isUserHL && userRole !== userRoles.SHIP

  const allMenuItems = [
    {
      key: paths.overview,
      label: (
        <>
          {selectedNavbarItem === paths.overview && (
            <div className="navbar__selected-item-border" />
          )}

          <div className="navbar__menu-item-container">
            <HomeFilled className="navbar__menu-item-icon" />
            <span className="navbar__menu-item-label">
              {t('navbar.overview')}
            </span>
          </div>
        </>
      ),
    },
    {
      key: paths.fleet,
      label: (
        <>
          {selectedNavbarItem === paths.fleet && (
            <div className="navbar__selected-item-border" />
          )}

          <div className="navbar__menu-item-container">
            <FolderFilled className="navbar__menu-item-icon" />
            <span className="navbar__menu-item-label">{t('navbar.fleet')}</span>
          </div>
        </>
      ),
    },
    {
      key: paths.ports.root,
      label: (
        <>
          {selectedNavbarItem === paths.ports.root && (
            <div className="navbar__selected-item-border" />
          )}

          <div className="navbar__menu-item-container">
            <Icon className="navbar__menu-item-icon" component={AnchorIcon} />
            <span className="navbar__menu-item-label">{t('navbar.ports')}</span>
          </div>
        </>
      ),
    },
  ]

  const shipMenuItems = [
    {
      key: `${paths.ship.root}/${userImoNumber}`,
      label: (
        <>
          <div className="navbar__selected-item-border" />

          <div className="navbar__menu-item-container">
            <HomeFilled className="navbar__menu-item-icon" />
            <span className="navbar__menu-item-label">
              {t('navbar.overview')}
            </span>
          </div>
        </>
      ),
    },
  ]

  const renderNavItems = () => (
    <Menu
      items={userRole === userRoles.SHIP ? shipMenuItems : allMenuItems}
      mode="inline"
      onSelect={onMenuItemSelected}
      selectedKeys={[location.pathname]}
      theme="dark"
    />
  )

  return (
    <Sider
      breakpoint="lg"
      className="navbar__container"
      collapsedWidth="0"
      width="14rem"
    >
      <div className="navbar__container-content">
        <div>
          <div className="cargomate-logo-container">
            <img
              alt={t('global.cargoMateLogoDescription')}
              className="cargomate-logo"
              onClick={goToHomePage}
              src={cargomateLogo}
            />
          </div>
          {renderNavItems()}
        </div>

        <Footer
          selectedNavbarItem={selectedNavbarItem}
          shouldShowFleetStatus={shouldShowFleetStatus}
        />
      </div>
    </Sider>
  )
}

Navbar.defaultProps = {
  cognitoGroup: null,
  userImoNumber: null,
  userRole: null,
}

Navbar.propTypes = {
  cognitoGroup: PropTypes.string,
  selectedNavbarItem: PropTypes.string.isRequired,
  totalNoOfNewNotifications: PropTypes.number.isRequired,
  userImoNumber: PropTypes.string,
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  cognitoGroup: selectors.cognitoGroupSelector(state),
  selectedNavbarItem: selectors.selectedNavbarItemSelector(state),
  totalNoOfNewNotifications: selectors.totalNoOfNewNotificationsSelector(state),
  userImoNumber: selectors.userImoNumberSelector(state),
  userRole: selectors.userRoleSelector(state),
})

export default connect(mapStateToProps)(Navbar)
