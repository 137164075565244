import Heading from 'components/Heading'
import React from 'react'

import setCrewNames from './Images/set-crew-names.png'
import setPortAndTerminal1 from './Images/set-port-and-terminal.png'
import setPortAndTerminal2 from './Images/set-port-and-terminal-2.png'
import setPortAndTerminal3 from './Images/set-port-and-terminal-3.png'
import setScheduledDeparture from './Images/set-scheduled-departure.png'
import setShipsTime from './Images/set-ships-time.png'
import startPortCall from './Images/start-port-call.png'

const StartEditPortCall = () => (
  <div>
    <Heading level={2}>Start / Edit port call</Heading>

    <p>
      Starting a port call is straightforward and only requires a few steps to
      complete.
    </p>

    <ul>
      <li>
        <a href="#set-voyage-number">Set Voyage Number</a>
      </li>
      <li>
        <a href="#set-port-and-terminal">Set Port and Terminal</a>
      </li>
      <li>
        <a href="#change-ships-time">Change ship’s time (optional)</a>
      </li>
      <li>
        <a href="#set-scheduled-departure">Set scheduled departure</a>
      </li>
      <li>
        <a href="#set-crew-names">Set Crew names</a>
      </li>
      <li>
        <a href="#edit-port-call-info">Editing port call information</a>
      </li>
    </ul>

    <Heading id="set-voyage-number" isDark level={3}>
      Set voyage number
    </Heading>
    <img src={startPortCall} />
    <p>
      Press ‘Set voyage number’ and enter the current voyage number for the port
      call.
    </p>

    <Heading id="set-port-and-terminal" isDark level={3}>
      Set Port and Terminal
    </Heading>
    <img src={setPortAndTerminal1} />
    <img src={setPortAndTerminal2} />
    <img src={setPortAndTerminal3} />
    <p>
      By pressing the ‘Use my location’ button, CargoMate will retrieve the
      position using GPS and can automate the selection for Port and Terminal.
      Please ensure you have a clear view of the sky to engage the GPS.
    </p>
    <p>
      You can also enter the port details manually. Only LOCODEs are accepted.
    </p>
    <p>
      Care should be taken when selecting the correct terminal. Please
      double-check with the terminal or local agent the terminal the vessel is
      in.
    </p>
    <p>
      If a LOCODE is not in the CargoMate database, or the terminal details are
      not correct, please contact CargoMate support.
    </p>

    <Heading id="change-ships-time" isDark level={3}>
      Change ship’s time (optional)
    </Heading>
    <img src={setShipsTime} />
    <p>
      In some ports, the vessel may be operating ship times differently to the
      local time. As such, an offset can be made in CargoMate.
    </p>
    <p>
      The offset should match the current ships time and can only be set in
      increments of 15 minutes.
    </p>
    <p>
      <strong>
        Once set, the ship’s time cannot be adjusted during a port call.
      </strong>
    </p>

    <Heading id="set-scheduled-departure" isDark level={3}>
      Set scheduled departure
    </Heading>
    <img src={setScheduledDeparture} />
    <p>Next, set the scheduled departure time from this port call.</p>
    <p>
      This time should be the most up-to-date available, and can be found from:
    </p>
    <ul>
      <li>Latest coastal schedule</li>
      <li>Local agent advice</li>
      <li>Terminal planner</li>
    </ul>
    <p>
      The scheduled departure time should be updated throughout the port call
      based on the latest available information.
    </p>

    <Heading id="set-crew-names" isDark level={3}>
      Set crew names
    </Heading>
    <img src={setCrewNames} />
    <p>
      A minimum of the names of the Captain and Chief Officer will be added to
      the cargo operations logbook. Deck officer names can also be added with
      the Add Deck Officer button.
    </p>
    <p>
      To remove Deck Officer names, click the bin icon to the right of their
      name.
    </p>

    <Heading isDark level={3}>
      Start Port Call
    </Heading>
    <p>
      Once all of the information has been entered, you can press the Start Port
      Call button.
    </p>

    <Heading id="edit-port-call-info" isDark level={3}>
      Editing Port Call information
    </Heading>
    <p>
      Only the ship’s time cannot be changed during a port call. All other
      information can be edited or updated during the port call.
    </p>
    <p>
      This is particularly important to consider when updating the Scheduled
      Departure time.
    </p>
  </div>
)

export default StartEditPortCall
