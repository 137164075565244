import { format, parseISO } from 'date-fns'
import { useNavigate, useParams } from 'react-router-dom'
import Card from 'components/Card'
import colors from 'global/styles/colors'
import DateRangeDropdown from 'components/DateRangeDropdown'
import FilterDropdown from 'components/FilterDropdown'
import getClassNames from 'classnames'
import InfoPopover from 'components/InfoPopover'
import isEmpty from 'lodash/isEmpty'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import paths from 'constants/paths'
import portCallStatuses from 'constants/portCallStatuses'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import { UNKNOWN_PORT } from 'constants/ports'
import userRoles from 'constants/userRoles'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import values from 'lodash/values'
import withLayout from 'components/layout'

import '../../global/styles/global.less'
import './ship.less'
import { FILTER_KEYS } from './ShipContainer'
import ShipHeading from './components/ShipHeading'

const Ship = ({
  currentPage,
  filterParams,
  isLoading,
  onFilterResult,
  onPageChange,
  pagination,
  portCalls,
  requestingPortCalls,
  resetFilterParams,
  userRole,
  vesselInfo,
}) => {
  let searchInput
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { imoNumber } = useParams()

  const isFilteringResult = !isEmpty(
    values(filterParams).filter(paramValue => !isEmpty(paramValue))
  )

  // The cell text render methods are used to render text
  const renderMethods = {
    cargoOperationsCommence: (_, portCall) =>
      portCall.cargoOperationsCommence
        ? `${format(
            parseISO(portCall.cargoOperationsCommence),
            timeDateAndYear
          )} ${
            portCall.portName === UNKNOWN_PORT
              ? `(${t('global.time.utcShort')})`
              : ''
          }`
        : t('ship.notCommenced'),
    cargoOperationsComplete: (_, portCall) => {
      if (
        portCall.portCallStatus === portCallStatuses.OPEN &&
        portCall.cargoOperationsCommence &&
        !portCall.cargoOperationsComplete
      ) {
        return t('ship.ongoing')
      }

      if (portCall.cargoOperationsComplete) {
        return `${format(
          parseISO(portCall.cargoOperationsComplete),
          timeDateAndYear
        )} ${
          portCall.portName === UNKNOWN_PORT
            ? `(${t('global.time.utcShort')})`
            : ''
        }`
      }

      if (!portCall.cargoOperationsCommence) {
        return t('ship.notAvailable')
      }

      if (portCall.portCallStatus === portCallStatuses.INTERRUPTED) {
        return (
          <div>
            {t('ship.portCallInterrupted')}
            <InfoPopover
              content={<Text>{t('ship.infoPopover.interruptedPortCall')}</Text>}
            />
          </div>
        )
      }

      return ''
    },
    duration: (_, portCall) => {
      if (portCall.cargoOpsDuration) {
        const { hours, minutes } = msToHoursAndMinutes(
          portCall.cargoOpsDuration
        )

        return prettyPrintHoursAndMinutes({
          andLabel: t('global.time.and'),
          hourLabel: t('global.time.hour'),
          hours,
          minuteLabel: t('global.time.minute'),
          minutes,
        })
      }

      if (!portCall.cargoOperationsCommence) {
        return t('ship.notAvailable')
      }

      if (portCall.portCallStatus === portCallStatuses.INTERRUPTED) {
        return t('ship.unknown')
      }

      return ''
    },
    port: (_, portCall) => `${portCall.portName} (${portCall.portLocode})`,
    voyageNumber: (_, portCall) => portCall.voyageNumber,
  }

  const getColumnSearchProps = (title, filterKey, isDateFilter = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      const onConfirm = filterValue => {
        resetFilterParams()
        onFilterResult({ [filterKey]: filterValue })
        confirm()
      }
      const onReset = () => {
        resetFilterParams()
        onFilterResult({})
        clearFilters()
      }

      return isDateFilter ? (
        <DateRangeDropdown
          onConfirm={onConfirm}
          onReset={onReset}
          value={filterParams[filterKey]}
        />
      ) : (
        <FilterDropdown
          onConfirm={onConfirm}
          onReset={onReset}
          setReference={node => (searchInput = node)}
          title={title}
          value={filterParams[filterKey]}
        />
      )
    },
    filterIcon: () => {
      const isFiltered = !isEmpty(filterParams[filterKey])

      return (
        <SearchOutlined style={{ color: isFiltered && colors.brandOrange }} />
      )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select())
      }
    },
  })

  const getRowClassName = (record, index) =>
    getClassNames('ship-table__row', {
      'ship-table__row--background': index % 2 === 0,
    })

  const columns = [
    {
      align: 'right',
      dataIndex: 'voyageNumber',
      title: t('ship.voyageNumber'),
      ...getColumnSearchProps(
        t('ship.voyageNumber').toLowerCase(),
        FILTER_KEYS.VOYAGE_NUMBER
      ),
    },
    {
      dataIndex: 'portName',
      filterMultiple: false,
      render: renderMethods.port,
      title: t('ship.port'),
      ...getColumnSearchProps(t('ship.port').toLowerCase(), FILTER_KEYS.PORT),
    },
    {
      children: [
        {
          align: 'center',
          dataIndex: 'cargoOperationsCommence',
          filterMultiple: false,
          render: renderMethods.cargoOperationsCommence,
          title: t('ship.commenced'),
          ...getColumnSearchProps(
            t('ship.commenced').toLowerCase(),
            FILTER_KEYS.CARGO_OPS_COMMENCED,
            true
          ),
        },
        {
          align: 'center',
          dataIndex: 'cargoOperationsComplete',
          filterMultiple: false,
          render: renderMethods.cargoOperationsComplete,
          title: t('ship.completed'),
          ...getColumnSearchProps(
            t('ship.completed').toLowerCase(),
            FILTER_KEYS.CARGO_OPS_COMPLETE,
            true
          ),
        },
        {
          align: 'center',
          dataIndex: 'duration',
          render: renderMethods.duration,
          title: t('ship.duration'),
        },
      ],
      title: t('ship.cargoOperations'),
    },
    {
      children: [
        {
          align: 'right',
          dataIndex: 'noOfDischargeMoves',
          title: t('ship.discharge'),
        },
        {
          align: 'right',
          dataIndex: 'noOfLoadMoves',
          title: t('ship.load'),
        },
        {
          align: 'right',
          key: uuidV4(),
          render: portCall =>
            portCall.noOfDischargeMoves + portCall.noOfLoadMoves,
          title: t('ship.total'),
        },
      ],
      title: t('ship.moves'),
    },
  ]

  return (
    <div className="page__container">
      <ShipHeading
        isLoading={isLoading}
        showPinButton={userRole !== userRoles.SHIP}
        vesselInfo={vesselInfo}
      />

      <Card title={t('ship.portCallHistory')}>
        {!requestingPortCalls && isEmpty(portCalls) && !isFilteringResult ? (
          <Text align="center">{t('ship.noPortCallsStarted')}</Text>
        ) : (
          <Table
            bordered
            columns={columns}
            dataSource={portCalls}
            history
            loading={requestingPortCalls}
            rowClassName={getRowClassName}
            rowKey={'portCallId'}
            onRow={portCall => ({
              onClick: () =>
                navigate(
                  `${paths.ship.root}/${imoNumber}/${paths.ship.portCall}/${portCall.portCallId}/${paths.portCall.summary}`
                ),
            })}
            pagination={{
              current: currentPage,
              onChange: onPageChange,
              pageSize: pagination.itemsPerPage,
              total: pagination.totalNumberOfItems,
            }}
          />
        )}
      </Card>
    </div>
  )
}

Ship.defaultProps = {
  pagination: {},
  portCalls: {},
  userRole: null,
  vesselInfo: null,
}

Ship.propTypes = {
  currentPage: PropTypes.number.isRequired,
  filterParams: PropTypes.shape({
    port: PropTypes.string.isRequired,
    voyageNumber: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFilterResult: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pagination: propTypes.portCallsPagination,
  portCalls: propTypes.ship,
  requestingPortCalls: PropTypes.bool.isRequired,
  resetFilterParams: PropTypes.func.isRequired,
  userRole: propTypes.userRole,
  vesselInfo: propTypes.vesselInfo,
}

export default withLayout(Ship)
