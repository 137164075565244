import Card from 'components/Card'
import { ReactComponent as DischargeContainer } from 'assets/icons/discharge-container.svg'
import { ReactComponent as LoadContainer } from 'assets/icons/load-container.svg'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as RestowContainer } from 'assets/icons/restows.svg'
import { useTranslation } from 'react-i18next'

import './gantryMoves.less'
import MoreInfoContent from './MoreInfoContent'
import RemainingMoves from '../RemainingMoves'

const GantryMoves = ({ gantryMovesSummary, isLoading }) => {
  const { t } = useTranslation()

  const { discharge, load, restows, total } = gantryMovesSummary

  const dischargeMovesRemaining = discharge
    ? discharge.remainingNoOfMoves
    : null
  const totalDischargeMoves = discharge ? discharge.totalNoOfMoves : null

  const loadMovesRemaining = load ? load.remainingNoOfMoves : null
  const totalLoadMoves = load ? load.totalNoOfMoves : null

  const restowsMovesRemaining = restows ? restows.remainingNoOfMoves : null
  const totalRestowsMoves = restows ? restows.totalNoOfMoves : null

  const totalMovesRemaining = total ? total.remainingNoOfMoves : null
  const totalMoves = total ? total.totalNoOfMoves : null

  return (
    <Card
      className="gantry-moves-card__card"
      moreInfoContent={<MoreInfoContent />}
      moreInfoTitle={t('portCall.gantryMoves.gantryMoves')}
      title={t('portCall.gantryMoves.gantryMoves')}
    >
      <div className="gantry-moves-card__container">
        <RemainingMoves
          hasMoves={!!discharge}
          iconComponent={DischargeContainer}
          isLoading={isLoading}
          noOfMovesRemaining={dischargeMovesRemaining}
          noOfTotalMoves={totalDischargeMoves}
          progressBarContainerClassName="gantry-moves-card__progress-bar-container"
          progressBarLabelClassName="gantry-moves-card__progress-bar-label"
          title={t('portCall.gantryMoves.discharge')}
        />

        <RemainingMoves
          hasMoves={!!load}
          iconComponent={LoadContainer}
          isLoading={isLoading}
          noOfMovesRemaining={loadMovesRemaining}
          noOfTotalMoves={totalLoadMoves}
          progressBarContainerClassName="gantry-moves-card__progress-bar-container"
          progressBarLabelClassName="gantry-moves-card__progress-bar-label"
          title={t('portCall.gantryMoves.load')}
        />

        <RemainingMoves
          hasMoves={!!restows}
          iconComponent={RestowContainer}
          isLoading={isLoading}
          noOfMovesRemaining={restowsMovesRemaining}
          noOfTotalMoves={totalRestowsMoves}
          progressBarContainerClassName="gantry-moves-card__progress-bar-container"
          progressBarLabelClassName="gantry-moves-card__progress-bar-label"
          title={t('portCall.gantryMoves.restow')}
        />

        <RemainingMoves
          hasMoves={!!discharge || !!load}
          isLoading={isLoading}
          noOfMovesRemaining={totalMovesRemaining}
          noOfTotalMoves={totalMoves}
          progressBarContainerClassName="gantry-moves-card__progress-bar-container"
          progressBarLabelClassName="gantry-moves-card__progress-bar-label"
          title={t('portCall.moves.totalTitle')}
        />
      </div>
    </Card>
  )
}

GantryMoves.propTypes = {
  gantryMovesSummary: propTypes.gantryMovesSummary.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default GantryMoves
