import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

const CargoMateMobileDocs = ({ setPage }) => {
  const navigateToCargoMateDevice = () =>
    setPage(userManualPages.cargoMateDevice)

  const navigateToBestPractices = () => setPage(userManualPages.bestPractices)

  const navigateToCargoMateHomeScreen = () =>
    setPage(userManualPages.cargoMateHomeScreen)

  const navigateToStartEditPortCall = () =>
    setPage(userManualPages.startEditPortCall)

  const navigateToProgressScreen = () => setPage(userManualPages.progressScreen)

  const navigateToCargoPlan = () => setPage(userManualPages.cargoPlan)

  const navigateToMobileDashboard = () =>
    setPage(userManualPages.mobileDashboard)

  const navigateToLearnMore = () => setPage(userManualPages.learnMore)

  return (
    <div>
      <Heading level={2}>CargoMate Mobile Documentation</Heading>

      <Heading isDark level={3}>
        How to use the CargoMate Mobile App
      </Heading>
      <p>
        The CargoMate Mobile App is for crew to monitor cargo operations. By
        entering data into the app, CargoMate can provide an understanding of
        gantry productivity and generate forecasts of cargo operations
        completion time for both sea and shore staff.
      </p>
      <p>
        The following instructions will guide you on how to use the CargoMate
        mobile app.
      </p>

      <Heading isDark level={3}>
        Contents
      </Heading>

      <ul>
        <li>
          <a onClick={navigateToCargoMateDevice}>CargoMate Device</a>
        </li>

        <li>
          <a onClick={navigateToBestPractices}>Best Practices</a>
        </li>

        <li>
          <a onClick={navigateToCargoMateHomeScreen}>CargoMate Home screen</a>
        </li>

        <li>
          <a onClick={navigateToStartEditPortCall}>Start / Edit port call</a>
        </li>

        <li>
          <a onClick={navigateToProgressScreen}>Progress screen</a>
        </li>

        <li>
          <a onClick={navigateToCargoPlan}>Cargo Plan</a>
        </li>

        <li>
          <a onClick={navigateToMobileDashboard}>Dashboard</a>
        </li>

        <li>
          <a onClick={navigateToLearnMore}>Learn More (Tutorial Videos)</a>
        </li>
      </ul>
    </div>
  )
}

CargoMateMobileDocs.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default CargoMateMobileDocs
