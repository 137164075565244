import { Badge, Button, Dropdown, Layout, Tooltip } from 'antd'
import { BellOutlined, GiftOutlined, UserOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from 'global/styles/colors'
import isEmpty from 'lodash/isEmpty'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import './header.less'
import EnableNotificationsPrompt from './components/EnableNotificationsPrompt'
import { getSearchData } from './util'
import MenuContent from './MenuContent'
import QuickSearch from './components/QuickSearch/QuickSearch'
import WhatsNewNotification from './components/WhatsNewNotification'

const { Header: AntDHeader } = Layout

// TODO - Show What's new button after issue 905 is resolved
// https://github.com/cargomate/cargomate-web-dashboard-v3/issues/905
const SHOULD_SHOW_UPDATES_BUTTON = false

const Header = ({
  closeWhatsNewNotification,
  hasUnseenNews,
  isUserSSO,
  onCancelEnableNotificationsPrompt,
  onConfirmEnableNotificationsPrompt,
  onNotificationsButtonClicked,
  shouldShowEnableNotificationsPrompt,
  shouldShowPortCallNotifications,
  shouldShowQuickSearch,
  shouldShowWhatsNewNotification,
  signedInAs,
  signOut,
  totalNoOfNewNotifications,
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [searchValue, setSearchValue] = useState()
  const [isQuickSearchLoading, setIsQuickSearchLoading] = useState(false)
  const [quickSearchData, setQuickSearchData] = useState([])

  const handleQuickSearchValueChanged = value => {
    if (isEmpty(value)) {
      setSearchValue()

      return
    }

    setSearchValue(value)
    getSearchData(value, setQuickSearchData, setIsQuickSearchLoading)
  }

  const navigateToWhatsNew = () => navigate(`${paths.whatIsNew}`)

  const isNotificationsButtonDisabled = pathname === paths.overview

  return (
    <AntDHeader className="header">
      {shouldShowQuickSearch && (
        <QuickSearch
          handleValueChanged={handleQuickSearchValueChanged}
          isLoading={isQuickSearchLoading}
          searchData={quickSearchData}
          value={searchValue}
        />
      )}

      {SHOULD_SHOW_UPDATES_BUTTON && (
        <Button
          className="header__button"
          icon={<GiftOutlined />}
          onClick={navigateToWhatsNew}
          shape="circle"
          type={hasUnseenNews ? 'danger' : null}
        />
      )}

      {shouldShowPortCallNotifications && (
        <Tooltip
          placement="left"
          title={isNotificationsButtonDisabled ? t('header.alreadyOpen') : ''}
        >
          <div className="header__button--notifications-container">
            <Button
              className="header__button"
              disabled={isNotificationsButtonDisabled}
              icon={<BellOutlined />}
              onClick={onNotificationsButtonClicked}
              shape="circle"
            />
            {!isNotificationsButtonDisabled && (
              <Badge
                className="header__button--badge"
                count={totalNoOfNewNotifications}
                style={{ backgroundColor: colors.brandOrange }}
              />
            )}
          </div>
        </Tooltip>
      )}

      <Dropdown
        trigger={['click']}
        dropdownRender={() => (
          <MenuContent
            isUserSSO={isUserSSO}
            signOut={() => signOut({ redirect: navigate })}
            signOutLabel={t('header.signOut')}
            signedInAsLabel={t('header.signedInAs')}
            signedInAsValue={signedInAs}
          />
        )}
      >
        <Button icon={<UserOutlined />} shape="circle" />
      </Dropdown>

      <WhatsNewNotification
        isVisible={shouldShowWhatsNewNotification}
        onCancel={closeWhatsNewNotification}
        onSeeMore={navigateToWhatsNew}
      />

      <EnableNotificationsPrompt
        isVisible={shouldShowEnableNotificationsPrompt}
        onCancel={onCancelEnableNotificationsPrompt}
        onEnableNotifications={onConfirmEnableNotificationsPrompt}
      />
    </AntDHeader>
  )
}

Header.defaultProps = {
  hasUnseenNews: false,
}

Header.propTypes = {
  closeWhatsNewNotification: PropTypes.func.isRequired,
  hasUnseenNews: PropTypes.bool,
  isUserSSO: PropTypes.bool.isRequired,
  onCancelEnableNotificationsPrompt: PropTypes.func.isRequired,
  onConfirmEnableNotificationsPrompt: PropTypes.func.isRequired,
  onNotificationsButtonClicked: PropTypes.func.isRequired,
  shouldShowEnableNotificationsPrompt: PropTypes.bool.isRequired,
  shouldShowPortCallNotifications: PropTypes.bool.isRequired,
  shouldShowQuickSearch: PropTypes.bool.isRequired,
  shouldShowWhatsNewNotification: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  signedInAs: PropTypes.string.isRequired,
  totalNoOfNewNotifications: PropTypes.number.isRequired,
}

export default Header
