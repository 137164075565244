import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

import dashboardOverview from './Images/dashboard-overview.png'

const CargoMateDashboardDocs = ({ setPage }) => {
  const navigateToDashboardOverview = () =>
    setPage(userManualPages.webDashboardOverview)

  const navigateToActivePortCall = () => setPage(userManualPages.activePortCall)

  const navigateToHistoricPortCalls = () =>
    setPage(userManualPages.historicPortCalls)

  const navigateToPortCallNotClosed = () =>
    setPage(userManualPages.portCallNotClosed)

  const navigateToOperationalConsiderations = () =>
    setPage(userManualPages.operationalConsiderations)

  return (
    <div>
      <Heading level={2}>CargoMate Web Dashboard Documentation</Heading>

      <Heading isDark level={3}>
        How to use the CargoMate Web Dashboard
      </Heading>
      <p>
        The CargoMate Web Dashboard is available to view the progress of port
        calls, in real-time and after they have been completed.
      </p>
      <p>
        After logging in, the first Dashboard view you will see is the Dashboard
        Overview.
      </p>
      <img src={dashboardOverview} />

      <Heading isDark level={3}>
        Contents
      </Heading>

      <ul>
        <li>
          <a onClick={navigateToDashboardOverview}>Dashboard Overview</a>
        </li>
        <li>
          <a onClick={navigateToActivePortCall}>Active Port Call</a>
        </li>
        <li>
          <a onClick={navigateToHistoricPortCalls}>Historic Port Calls</a>
        </li>
        <li>
          <a onClick={navigateToPortCallNotClosed}>Port call not closed</a>
        </li>
        <li>
          <a onClick={navigateToOperationalConsiderations}>
            Operational Considerations
          </a>
        </li>
      </ul>
    </div>
  )
}

CargoMateDashboardDocs.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default CargoMateDashboardDocs
