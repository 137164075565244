import Heading from 'components/Heading'
import React from 'react'

import bayActions from './Images/bay-actions.png'
import bayTempStop from './Images/bay-temp-stop.png'
import tempStopBayActions from './Images/temp-stop-bay-actions.png'
import tempStopGantry from './Images/temp-stop-gantry.png'

const TempStopSingleGantry = () => (
  <div>
    <Heading level={2}>Temporary stop a single gantry</Heading>

    <p>
      A gantry that might be undergoing a shift change, coffee break or
      undergoing maintenance can be logged as Temporary Stop.
    </p>
    <p>
      As with Dismissing a gantry, press on the bay to view it and press on the
      yellow button with a coffee icon to temp stop it.
    </p>
    <img src={bayActions} />

    <p>
      On the ‘Temp stop gantry’ screen, enter the time the gantry stopped
      working and press ‘Log’.
    </p>
    <img src={tempStopGantry} />

    <p>The gantry status is now shown as ‘Temp stopped’.</p>
    <img src={tempStopBayActions} />

    <p>
      On the Cargo Plan, the bay will now be highlighted yellow to show the
      gantry is temporarily stopped.
    </p>
    <img src={bayTempStop} />
  </div>
)

export default TempStopSingleGantry
