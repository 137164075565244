import Heading from 'components/Heading'
import React from 'react'

import arrivalVesselDrafts from './Images/arrival-vessel-drafts.png'
import arrivalWaterDensity from './Images/arrival-water-density.png'
import gangwayRiggedAndChecked from './Images/gangway-rigged-and-checked.png'
import totalArrivalContainers from './Images/total-arrival-containers.png'
import vesselArrivalInfo from './Images/vessel-arrival-info.png'
import vesselReadyForOperation from './Images/vessel-ready-for-operation.png'

const VesselArrivalInfo = () => (
  <div>
    <Heading level={2}>Vessel arrival info</Heading>

    <img src={vesselArrivalInfo} />

    <Heading hasNoMarginBottom isDark level={4}>
      Sections
    </Heading>
    <ul>
      <li>
        <a href="#gangway-rigged-and-checked">Gangway Rigged and Checked</a>
      </li>
      <li>
        <a href="#arrival-vessel-drafts">Arrival Vessel Drafts</a>
      </li>
      <li>
        <a href="#arrival-water-density">Arrival Water Density</a>
      </li>
      <li>
        <a href="#total-arrival-containers">Total Arrival Containers</a>
      </li>
      <li>
        <a href="#vessel-ready-for-operation">Vessel Ready for Operation</a>
      </li>
    </ul>

    <Heading id="gangway-rigged-and-checked" isDark level={3}>
      Gangway Rigged and Checked
    </Heading>
    <img src={gangwayRiggedAndChecked} />
    <p>
      Enter the time that the gangway was considered rigged and ready for safe
      access to the vessel. Also, choose which side the vessel is alongside.
    </p>
    <p>
      Notes can also be added to provide additional context to this event in the
      ‘Add/View notes’ screen in the bottom left.
    </p>

    <Heading id="arrival-vessel-drafts" isDark level={3}>
      Arrival Vessel Drafts
    </Heading>
    <img src={arrivalVesselDrafts} />
    <p>
      Enter the drafts both by visual inspection and as calculated by the ship’s
      stability software.
    </p>
    <p>
      Drafts should be to two decimal places. A calculated difference is shown
      for clarity.
    </p>

    <Heading id="arrival-water-density" isDark level={3}>
      Arrival Water Density
    </Heading>
    <img src={arrivalWaterDensity} />
    <p>
      Enter the water density as measured by a hydrometer. The figure should be
      to three decimal places.
    </p>

    <Heading id="total-arrival-containers" isDark level={3}>
      Total Arrival Containers
    </Heading>
    <img src={totalArrivalContainers} />
    <p>
      The total arrival containers are taken directly from the Discharge Baplie.
    </p>
    <p>
      Containers figures should be changed if they are known to be incorrect.
    </p>

    <Heading id="vessel-ready-for-operation" isDark level={3}>
      Vessel Ready for Operation
    </Heading>
    <img src={vesselReadyForOperation} />
    <p>Once the vessel is ready for operation, this should be entered here.</p>
    <p>
      Cargo operations commence time is the time of the first gearbox/container
      move or on the start of unlashing.
    </p>
  </div>
)

export default VesselArrivalInfo
