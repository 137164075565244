import Heading from 'components/Heading'
import React from 'react'

import departureVesselDrafts from './Images/departure-vessel-drafts.png'
import departureWaterDensity from './Images/departure-water-density.png'
import totalDepartureContainers from './Images/total-departure-containers.png'
import totalSpecialCargoContainers from './Images/total-special-cargo-containers.png'

const VesselDepartureInfo = () => (
  <div>
    <Heading level={2}>Vessel departure info</Heading>

    <p>
      When cargo operations is nearing completion, the following data should be
      entered.
    </p>
    <ul>
      <li>
        <a href="#departure-vessel-drafts">Departure Vessel Drafts</a>
      </li>
      <li>
        <a href="#departure-water-density">Departure Water Density</a>
      </li>
      <li>
        <a href="#total-departure-containers">Total Departure Containers</a>
      </li>
      <li>
        <a href="#total-special-cargo-containers">
          Total Special Cargo Containers
        </a>
      </li>
    </ul>

    <Heading id="departure-vessel-drafts" isDark level={3}>
      Departure vessel drafts
    </Heading>
    <p>
      As with arrival drafts, the departure drafts both by visual inspection and
      calculation should be entered.
    </p>
    <p>Drafts should be entered to two decimal places.</p>
    <img src={departureVesselDrafts} />

    <Heading id="departure-water-density" isDark level={3}>
      Departure water density
    </Heading>
    <p>
      Enter the water density as measured by a hydrometer. The figure should be
      to three decimal places.
    </p>
    <img src={departureWaterDensity} />

    <Heading id="total-departure-containers" isDark level={3}>
      Total departure containers
    </Heading>
    <p>
      The number of total departure containers comes from the load baplie.
      Container figures can be changed if they are incorrect.
    </p>
    <img src={totalDepartureContainers} />

    <Heading id="total-special-cargo-containers" isDark level={3}>
      Total special cargo containers
    </Heading>
    <p>
      The number of total special cargo containers comes from the load baplie.
      Container figures can be changed if they are incorrect.
    </p>
    <img src={totalSpecialCargoContainers} />
  </div>
)

export default VesselDepartureInfo
