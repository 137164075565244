import Heading from 'components/Heading'
import React from 'react'

import resumeCargoOps from './Images/resume-cargo-ops.png'
import tempStopAll from './Images/temp-stop-all.png'
import tempStopCargo from './Images/temp-stop-cargo.png'
import tempStoppedCargo from './Images/temp-stopped-cargo.png'
import tempStopsInCargoOps from './Images/temp-stops-in-cargo-ops.png'

const TempStopAllGantries = () => (
  <div>
    <Heading level={2}>Temporary stop all gantries</Heading>

    <p>
      When a shift change occurs, all gantries may temporary stop. Instead of
      setting all gantries to temporary stop individually, the ‘Temp stop cargo
      ops’ screen can be used to set all gantries at the same time.
    </p>
    <p>On the cargo plan, press on the button with the coffee cup</p>
    <img src={tempStopAll} />

    <p>
      The ‘Temp stops in cargo ops’ screen will show a list of all temp stop
      cargo events. If no events have been logged, it will show ‘No temp stop
      logged’.
    </p>
    <p>Press on ‘Temp stop cargo ops’.</p>
    <img src={tempStopsInCargoOps} />

    <p>
      Select the gantries which have stopped and enter a time for the start of
      the stoppage.
    </p>
    <img src={tempStopCargo} />

    <p>
      Press ‘Log’ and you will see the event added which you can delete or edit
      the time of.
    </p>
    <img src={tempStoppedCargo} />

    <p>The cargo plan will show all gantries as temp stopped.</p>
    <img src={resumeCargoOps} />
    <p>
      To change gantries back to active, press the green play button and enter a
      time for the end of the shift change or break.
    </p>
  </div>
)

export default TempStopAllGantries
