import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { dateAndYear } from 'constants/time'
import fileDownload from 'js-file-download'
import { format } from 'date-fns'

import * as actions from './actions'
import * as portCallSelectors from './selectors'
import actionTypes from './actionTypes'
import restUtils from '../restUtils'

const endpoint = {
  comments: 'comments',
  containerMovesProgress: 'container-moves-progress',
  departureEstimation: 'departure-estimation',
  draftSurvey: 'draft-survey',
  gantryMovesSummary: 'gantry-moves-summary',
  gearboxLocations: 'gearbox-locations',
  general: 'general',
  logbookRemarks: 'logbook-remarks',
  milestones: 'milestones',
  productivity: 'productivity',
  reportsSummary: 'reports-summary',
  root: 'port-call',
  shipOverview: 'ship-overview',
}

function* requestPortCallGeneral(action) {
  return yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.general}`,
    setRequestSuccessful: actions.portCallGeneralRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallGeneral,
  })
}

function* requestDepartureEstimation(action) {
  return yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.departureEstimation}`,
    setRequestSuccessful: actions.portCallDepartureEstimationRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallDepartureEstimation,
  })
}

function* requestLogbookRemarks(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.logbookRemarks}`,
    setRequestSuccessful: actions.portCallLogbookRemarksRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallLogbookRemarks,
  })
}

function* requestContainerMovesProgress(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.containerMovesProgress}`,
    setRequestSuccessful:
      actions.portCallContainerMovesProgressRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallContainerMovesProgress,
  })
}

function* requestGantryMovesSummary(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.gantryMovesSummary}`,
    setRequestSuccessful: actions.portCallGantryMovesSummaryRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallGantryMovesSummary,
  })
}

function* requestSummaryTabContent(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.reportsSummary}`,
    setRequestSuccessful: actions.portCallReportsSummaryRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallReportsSummary,
  })

  const reportsSummary = yield select(
    portCallSelectors.portCallReportsSummarySelector
  )

  if (!reportsSummary) {
    yield all([
      call(requestContainerMovesProgress, action),
      call(requestGantryMovesSummary, action),
    ])
  }
}

function* requestPortCallMilestones(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.milestones}`,
    setRequestSuccessful: actions.portCallMilestonesRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallMilestones,
  })
}

function* requestPortCallSummaryTab(action) {
  yield all([
    call(requestSummaryTabContent, action),
    call(requestPortCallMilestones, action),
  ])
}

function* requestPortCallShipOverviewTab(action) {
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.shipOverview}`,
    setRequestSuccessful: actions.portCallShipOverviewRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallShipOverview,
  })
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.draftSurvey}`,
    setRequestSuccessful: actions.portCallDraftSurveyRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallDraftSurvey,
  })
  yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.gearboxLocations}`,
    setRequestSuccessful: actions.portCallGearboxLocationsRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallGearboxLocations,
  })
}

function* requestPortCallProductivity(action) {
  return yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.productivity}`,
    setRequestSuccessful: actions.portCallProductivityRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallProductivity,
  })
}

function* downloadPortCallReport(action) {
  const response = yield restUtils.downloadPortCallReport({
    imoNumber: action.payload.imoNumber,
    portCallId: action.payload.portCallId,
  })

  if (response && response.body) {
    const createdAtText = format(
      new Date(action.payload.createdAt),
      dateAndYear
    )

    const filename = `${createdAtText} - ${action.payload.shipName} - ${action.payload.portName} - port-call-report.pdf`

    fileDownload(response.body, filename)
  }
}

function* downloadTerminalPerformanceReport(action) {
  const response = yield restUtils.downloadTerminalPerformanceReport(
    action.payload.portCallId
  )

  if (response && response.body) {
    const createdAtText = format(
      new Date(action.payload.createdAt),
      dateAndYear
    )

    const filename = `${createdAtText} - ${action.payload.shipName} - ${action.payload.portName} - terminal-performance-report.csv`

    fileDownload(response.body, filename)
  }
}

function* requestPortCallComments(action) {
  return yield restUtils.get({
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.comments}`,
    setRequestSuccessful: actions.portCallCommentsRequestSuccessful,
    setRequestingState: actions.setRequestingPortCallComments,
  })
}

function* requestPortCallCommentsTab(action) {
  yield all([
    call(requestPortCallComments, action),
    call(requestLogbookRemarks, action),
  ])
}

function* postPortCallComment(action) {
  const SUCCESS_STATUS_CODE = 200
  const response = yield restUtils.post({
    content: action.payload.comment,
    endpoint: `${endpoint.root}/${action.payload.portCallId}/${endpoint.comments}`,
    setRequestingState: actions.setIsPostingComment,
  })

  if (!!response && response.status === SUCCESS_STATUS_CODE) {
    action.payload.resetForm()
    yield put(actions.clearCommentDraft())
    yield requestPortCallComments(action)
  }
}

export function* watchDownloadTerminalPerformanceReport() {
  yield takeLatest(
    actionTypes.DOWNLOAD_TERMINAL_PERFORMANCE_REPORT,
    downloadTerminalPerformanceReport
  )
}

export function* watchDownloadPortCallReport() {
  yield takeLatest(
    actionTypes.DOWNLOAD_PORT_CALL_REPORT,
    downloadPortCallReport
  )
}

export function* watchRequestPortCallGeneral() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_GENERAL,
    requestPortCallGeneral
  )
}

export function* watchRequestPortCallProductivity() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_PRODUCTIVITY,
    requestPortCallProductivity
  )
}

export function* watchRequestPortCallSummaryTab() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_SUMMARY_TAB,
    requestPortCallSummaryTab
  )
}

export function* watchRequestPortCallShipOverviewTab() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_SHIP_OVERVIEW_TAB,
    requestPortCallShipOverviewTab
  )
}

export function* watchRequestPortCallDepartureEstimation() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_DEPARTURE_ESTIMATION,
    requestDepartureEstimation
  )
}

export function* watchRequestPortCallCommentsTab() {
  yield takeLatest(
    actionTypes.REQUEST_PORT_CALL_COMMENTS_TAB,
    requestPortCallCommentsTab
  )
}

export function* watchPostPortCallComment() {
  yield takeLatest(actionTypes.POST_PORT_CALL_COMMENT, postPortCallComment)
}
