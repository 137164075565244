import Heading from 'components/Heading'
import React from 'react'

import homeScreen from './Images/home-screen.png'
import progressScreen from './Images/progress-screen.png'

const CargoMateHomeScreen = () => (
  <div>
    <Heading level={2}>CargoMate Home screen</Heading>

    <p>On starting a port call, you will be shown the following home screen.</p>
    <p>
      Press ‘Learn More’ to view offline tutorial videos on how to operate your
      CargoMate device, or, press Start port call to begin entering data for a
      new port call.
    </p>

    <img src={homeScreen} />

    <p>
      If you see a screen similar to the one below, a previous port call is
      still open and will need to be closed first.
    </p>

    <img src={progressScreen} />

    <p>To do this, please go to the section ‘End port call’.</p>
  </div>
)

export default CargoMateHomeScreen
