import { Col } from 'antd'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import './commentsTab.less'
import Comments from './Comments'
import LogbookRemarks from './LogbookRemarks'

const CommentsTab = ({
  comments,
  handlePostComment,
  isCountryUnknown,
  isPostingComment,
  isRequestingComments,
  isRequestingLogbookRemarks,
  logbookRemarks,
}) => (
  <Col span={24}>
    <div className="comments-tab__container grid-gap">
      <div>
        <Comments
          comments={comments}
          handlePostComment={handlePostComment}
          isCountryUnknown={isCountryUnknown}
          isLoading={isRequestingComments}
          isPostingComment={isPostingComment}
        />
      </div>
      <div>
        <LogbookRemarks
          isLoading={isRequestingLogbookRemarks}
          remarks={logbookRemarks}
        />
      </div>
    </div>
  </Col>
)

CommentsTab.propTypes = {
  comments: PropTypes.arrayOf(propTypes.portCallComment).isRequired,
  handlePostComment: PropTypes.func.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
  isPostingComment: PropTypes.bool.isRequired,
  isRequestingComments: PropTypes.bool.isRequired,
  isRequestingLogbookRemarks: PropTypes.bool.isRequired,
  logbookRemarks: propTypes.logbookRemarks.isRequired,
}

export default CommentsTab
