import React, { useEffect } from 'react'
import { Carousel } from 'antd'
import { connect } from 'react-redux'
import Heading from 'components/Heading'
import news from 'global/news'
import propTypes from 'global/propTypes'
import { selectors } from 'domain'
import Text from 'components/Text'
import uniq from 'lodash/uniq'
import { useTranslation } from 'react-i18next'
import withLayout from 'components/layout'

import './whatIsNew.less'

const WhatIsNew = ({ userRole }) => {
  const { t } = useTranslation()

  const newsForUser = news.filter(item => item.userRoles.includes(userRole))

  useEffect(() => {
    const seenNewsIds = JSON.parse(localStorage.getItem('news-id-seen')) || []

    const currentNewsIds = newsForUser.map(item => item.id)

    localStorage.setItem(
      'news-id-seen',
      JSON.stringify(uniq([...seenNewsIds, ...currentNewsIds]))
    )
  }, [newsForUser])

  return (
    <div className="page__container">
      <Heading level={1}>{t('whatIsNew.title')}</Heading>

      <Carousel className="what-is-new__carousel" dots={true} draggable>
        {newsForUser.map(item => (
          <div key={item.id}>
            <div className="what-is-new__image-container">
              <img
                alt={item.title}
                className="what-is-new__image"
                src={require(`assets/images/${item.image}`)}
              />
            </div>

            <Heading hasNoMarginBottom isCentered isDark level={1}>
              {item.title}
            </Heading>

            <Text align="center" className="what-is-new__body">
              {item.body}
            </Text>

            <Text align="center" className="what-is-new__date" isLabel>
              {`${t('whatIsNew.added')} ${item.dateAdded}`}
            </Text>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

WhatIsNew.defaultProps = {
  userRole: null,
}

WhatIsNew.propTypes = {
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  userRole: selectors.userRoleSelector(state),
})

export default connect(mapStateToProps)(withLayout(WhatIsNew))
