import cloneDeep from 'lodash/cloneDeep'
import { createSelector } from '@reduxjs/toolkit'
import includes from 'lodash/includes'
import scheduleStatuses from 'constants/scheduleStatuses'

import { pinnedShipsSelector } from '../Ship/selectors'

const overviewSelector = state => state.overview

export const sortOverviewShipsBySelector = createSelector(
  overviewSelector,
  overview => overview.sortBy
)

export const activePortCallsSelector = createSelector(
  overviewSelector,
  pinnedShipsSelector,
  (overview, pinnedShips) => {
    const activePortCalls = cloneDeep(overview.activePortCalls.portCalls)
    const imoNumbersOfPinnedShips = pinnedShips.map(ship => ship.imoNumber)

    if (!activePortCalls) {
      return []
    }

    activePortCalls.forEach(portCall => {
      portCall.isShipPinned = includes(
        imoNumbersOfPinnedShips,
        portCall.imoNumber
      )

      // Sets the default schedule status to 'On Schedule' for
      // port calls without an estimated cargo ops complete time
      if (!portCall.estimatedDiffToSchedule) {
        portCall.estimatedDiffToSchedule = {
          estimatedMsFinishedDiffToSchedule: null,
          estimatedStatus: scheduleStatuses.ON_SCHEDULE,
        }
      }
    })

    return activePortCalls
  }
)

export const currentActivePortCallsPageSelector = createSelector(
  overviewSelector,
  overview =>
    overview.activePortCalls.currentPage
      ? overview.activePortCalls.currentPage
      : 1
)

export const requestingActivePortCallsPageSelector = createSelector(
  overviewSelector,
  overview => overview.activePortCalls.requestingNextPage
)

export const activePortCallsPaginationSelector = createSelector(
  overviewSelector,
  overview => overview.activePortCalls.pagination
)

export const recentlyCompletedPortCallsSelector = createSelector(
  overviewSelector,
  overview => overview.recentlyCompletedPortCalls
)

export const isRequestingActivePortCallsSelector = createSelector(
  overviewSelector,
  overview => overview.requestingActivePortCalls
)

export const isRequestingRecentlyCompletedPortCalls = createSelector(
  overviewSelector,
  overview => overview.requestingRecentlyCompletedPortCalls
)

export const activePortCallsFilterBySelector = createSelector(
  overviewSelector,
  overview => overview.filterBy
)
