import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

const Home = ({ setPage }) => {
  const navigateToMobileDocs = () =>
    setPage(userManualPages.cargoMateMobileDocs)

  const navigateToCargoMateDashboardDocs = () =>
    setPage(userManualPages.cargoMateDashboardDocs)

  return (
    <div>
      <Heading level={2}>CargoMate Documentation</Heading>
      <ul>
        <li>
          <a onClick={navigateToMobileDocs}>CargoMate Mobile Documentation</a>
        </li>

        <li>
          <a onClick={navigateToCargoMateDashboardDocs}>
            CargoMate Dashboard Documentation
          </a>
        </li>
      </ul>
    </div>
  )
}

Home.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default Home
