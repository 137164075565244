import Heading from 'components/Heading'
import React from 'react'

import logbookEventCommitted from './Images/logbook-event-committed.png'
import logbookEvents from './Images/logbook-events.png'

const LogbookEvents = () => (
  <div>
    <Heading level={2}>Logbook events</Heading>

    <p>
      Logbook events can be entered for any event that you wish to make a record
      of. Examples of which may include:
    </p>
    <ul>
      <li>Stevedores onboard/away</li>
      <li>Bunker barge alongside/away</li>
      <li>Heavy weather in port</li>
      <li>Gantry breakdown or maintenance.</li>
    </ul>
    <p>All logbook events can be edited and deleted.</p>
    <img src={logbookEvents} />

    <Heading isDark level={3}>
      Add Logbook event
    </Heading>
    <p>Press the ‘Add event’ button to add a new event.</p>
    <p>
      Enter a date, time and the details of the event to be recorded. Press
      ‘Add’ to create the event in the logbook.
    </p>
    <img src={logbookEventCommitted} />
    <img />
  </div>
)

export default LogbookEvents
